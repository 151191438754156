/* eslint-disable indent */
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import 'react-datetime-picker/dist/DateTimePicker.css';

import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  List,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ErrorText from 'components/ErrorText/ErrorText';
import Popup from 'components/Popup/Popup';
import SnackbarContext from 'contexts/snackbarContext';
import { debounce } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import DateTimePicker from 'react-datetime-picker';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { getGroupsForOrganisation } from 'services/group.service';
import {
  createNewNotification,
  getNotificationById,
  updateNotification,
} from 'services/notification.service';
import { getOrganizationNames } from 'services/organisation.service';
import { getUsers } from 'services/users.services';
import { theme } from 'theme';
import { GroupType } from 'types/groups.type';
import {
  CreateNotificationRequest,
  NotificationTargetType,
  NotificationType,
} from 'types/notification.type';
import { OrganisationType } from 'types/organisations.type';
import { UserType } from 'types/users.type';

type CreateNotificationFormType = Omit<NotificationType, 'id' | 'targetIds'>;

type ScheduleType = 'instant' | 'scheduled';

const CustomForm = styled('form')`
  width: 100%;
`;

type State = {
  isPreview: boolean;
  notification?: NotificationType;
  organisations: OrganisationType[];
  selectedOrganisation: string[] | [];
  groups: GroupType[];
  selectedGroups: string[] | [];
  users: UserType[];
  selectedUsers: string[] | [];
  scheduleType: ScheduleType;
  isModalOpen: boolean;
};

type Action =
  | { type: 'SET_IS_PREVIEW'; payload: boolean }
  | { type: 'SET_NOTIFICATION'; payload: NotificationType }
  | { type: 'SET_ORGANISATIONS'; payload: OrganisationType[] }
  | { type: 'SET_GROUPS'; payload: GroupType[] }
  | { type: 'SET_USERS'; payload: UserType[] }
  | { type: 'SET_SELECTED_ORGANISATION'; payload: string[] | [] }
  | { type: 'SET_SELECTED_GROUPS'; payload: string[] | [] }
  | { type: 'SET_SELECTED_USERS'; payload: string[] | [] }
  | { type: 'SET_SCHEDULE_TYPE'; payload: ScheduleType }
  | { type: 'SET_IS_MODAL_OPEN'; payload: boolean };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_IS_PREVIEW':
      return { ...state, isPreview: action.payload };
    case 'SET_NOTIFICATION':
      return { ...state, notification: action.payload };
    case 'SET_ORGANISATIONS':
      return { ...state, organisations: action.payload };
    case 'SET_GROUPS':
      return { ...state, groups: action.payload };
    case 'SET_USERS':
      return { ...state, users: action.payload };
    case 'SET_SELECTED_ORGANISATION':
      return { ...state, selectedOrganisation: action.payload };
    case 'SET_SELECTED_GROUPS':
      return { ...state, selectedGroups: action.payload };
    case 'SET_SELECTED_USERS':
      return { ...state, selectedUsers: action.payload };
    case 'SET_SCHEDULE_TYPE':
      return { ...state, scheduleType: action.payload };
    case 'SET_IS_MODAL_OPEN':
      return { ...state, isModalOpen: action.payload };
    default:
      return state;
  }
};

export default function CreateNotificationForm() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    control,
    watch,
  } = useForm<CreateNotificationFormType>();

  const [
    {
      isPreview,
      notification,
      organisations,
      selectedOrganisation,
      groups,
      selectedGroups,
      users,
      selectedUsers,
      scheduleType,
      isModalOpen,
    },
    dispatch,
  ] = useReducer(reducer, {
    isPreview: false,
    notification: undefined,
    organisations: [],
    selectedOrganisation: [],
    groups: [],
    selectedGroups: [],
    users: [],
    selectedUsers: [],
    scheduleType: 'instant',
    isModalOpen: false,
  });

  const watchTarget = watch('targetType');

  const { handleOpen } = useContext(SnackbarContext);

  const loadOrgs = useCallback(
    debounce(async () => {
      getOrganizationNames().then((res) => {
        dispatch({ type: 'SET_ORGANISATIONS', payload: res.data });
      });
    }, 300),
    [dispatch],
  );

  useEffect(() => {
    if (!isPreview && !organisations.length) {
      loadOrgs();
    }

    return () => {
      loadOrgs.cancel();
    };
  }, [isPreview, organisations.length, loadOrgs]);

  const getSelectedNotification = useCallback(
    debounce(async (id: string) => {
      const notification = await getNotificationById(id);

      if (!notification) return;

      setValue('title_HU', notification.title_HU);
      setValue('title_EN', notification.title_EN);
      setValue('description_HU', notification.description_HU);
      setValue('description_EN', notification.description_EN);
      setValue('type', notification.type);
      setValue('timestamp', notification.timestamp);
      setValue('targetType', notification.targetType);

      if (notification.targetType === 'organisation') {
        handleOrgChange(notification.targets[0].id);
      } else if (notification.targetType === 'group') {
        const target = organisations.filter((org) =>
          org.__groups__.find(
            (__groups__) => __groups__.id === notification.targets[0].id,
          ),
        )[0];

        if (target) {
          handleOrgChange(target.id);
        }
      }

      if (notification.targetType === 'user') {
        getUsers().then((res) => {
          dispatch({ type: 'SET_USERS', payload: res.data });
        });
      }

      if (['user', 'group'].includes(notification.targetType)) {
        dispatch({
          type:
            notification.targetType === 'user'
              ? 'SET_SELECTED_USERS'
              : 'SET_SELECTED_GROUPS',
          payload: notification.targets.map((target) => target.id) as
            | string[]
            | [],
        });
      }

      dispatch({
        type: 'SET_NOTIFICATION',
        payload: notification,
      });

      const isDelivered =
        Date.now() > new Date(notification.timestamp).getTime();

      if (isDelivered) {
        dispatch({
          type: 'SET_IS_PREVIEW',
          payload: true,
        });
      } else {
        dispatch({
          type: 'SET_SCHEDULE_TYPE',
          payload: 'scheduled',
        });
      }
    }, 300),
    [organisations],
  );

  useEffect(() => {
    if (id) {
      getSelectedNotification(id);
    }

    return () => {
      getSelectedNotification.cancel();
    };
  }, [id, organisations]);

  const onSubmit = (data: CreateNotificationFormType) => {
    const { targetType } = data;
    const ids = {
      user: selectedUsers,
      group: selectedGroups,
      organisation: selectedOrganisation,
    }[targetType].filter(Boolean);

    const newNotification: CreateNotificationRequest = {
      title_EN: data.title_EN,
      title_HU: data.title_HU,
      description_EN: data.description_EN,
      description_HU: data.description_HU,
      type: data.type,
      targetType: data.targetType,
      targetIds: ids,
      timestamp: scheduleType === 'instant' ? null : data.timestamp,
    };

    const notificationPromise = id
      ? updateNotification(id, newNotification)
      : createNewNotification(newNotification);

    notificationPromise
      .then(() => {
        handleOpen(
          id
            ? t('createNotificationForm.notificationModifiedMessage')
            : t('createNotificationForm.notificationCreatedMessage'),
          'success',
        );
        navigate('/notifications');
      })
      .catch((err) => {
        console.log(err);
        handleOpen(
          id
            ? t('createNotificationForm.notificationModifiedErrorMessage')
            : t('createNotificationForm.notificationCreatedErrorMessage'),
          'error',
        );
      });
  };

  const checkKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  const handleTargetTypeChange = (type: NotificationTargetType) => {
    setValue('targetType', type);
    if (type === 'user' && !users.length) {
      getUsers().then((res) => {
        dispatch({ type: 'SET_USERS', payload: res.data });
      });
    }
  };

  const handleOrgChange = useCallback(
    (org: string) => {
      dispatch({ type: 'SET_SELECTED_ORGANISATION', payload: [org] });
      if (org) {
        getGroupsForOrganisation(org).then((res) => {
          dispatch({ type: 'SET_GROUPS', payload: res.data.groups });
        });
      }
    },
    [dispatch, getGroupsForOrganisation],
  );

  const isTargetValid = useMemo(() => {
    const targets = {
      user: selectedUsers,
      group: selectedGroups,
      organisation: selectedOrganisation,
    };

    return !!targets[watchTarget]?.length;
  }, [watchTarget, selectedUsers, selectedGroups, selectedOrganisation]);

  const orgMenuItems = useMemo(() => {
    return organisations.map((org) => (
      <MenuItem key={org?.id} value={org?.id}>
        {org.companyName}
      </MenuItem>
    ));
  }, [organisations]);

  const groupMenuItems = useMemo(() => {
    return groups?.map((group) => (
      <MenuItem key={group?.id} value={group?.id}>
        {group.displayName}
      </MenuItem>
    ));
  }, [groups]);

  const userMenuItems = useMemo(() => {
    return users.map((user) => (
      <MenuItem key={user?.id} value={user?.id}>
        {`${user.firstname} ${user.lastname} - ${user.displayName ?? ''}`}
      </MenuItem>
    ));
  }, [users]);

  const renderTargets = () => {
    const targets = notification?.targets ?? [];

    return targets.map((target, index) => {
      const { id } = target;
      let primary = '';

      if ('companyName' in target) {
        primary = target.companyName;
      } else if ('firstname' in target && 'lastname' in target) {
        primary = `${target.firstname} ${target.lastname} - ${
          target.displayName ?? ''
        }`;
      } else {
        primary = target.displayName;
      }

      return (
        <ListItem
          key={id}
          disableGutters
          divider={index !== targets.length - 1}
          sx={{
            opacity: 0.6,
          }}
        >
          <ListItemText primary={primary} />
        </ListItem>
      );
    });
  };

  return (
    <Card sx={{ padding: '40px', maxWidth: '800px' }}>
      <CustomForm onKeyDown={checkKeyDown}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="title_HU"
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('formFields.title_hun')}
                  type="text"
                  error={errors.title_HU ? true : false}
                  disabled={isPreview}
                />
              )}
            />
            {errors.title_HU && (
              <ErrorText>{t('formErrors.titleRequired')}</ErrorText>
            )}
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="title_EN"
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('formFields.title_eng')}
                  type="text"
                  error={errors.title_EN ? true : false}
                  disabled={isPreview}
                />
              )}
            />
            {errors.title_EN && (
              <ErrorText>{t('formErrors.titleRequired')}</ErrorText>
            )}
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="description_HU"
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.description_HU}
                  fullWidth
                  type="text"
                  label={t('formFields.description_hun')}
                  multiline
                  rows={6}
                  disabled={isPreview}
                />
              )}
            />
            {errors.description_HU &&
              errors.description_HU.type === 'required' && (
                <ErrorText>{t('formErrors.descriptionRequired')}</ErrorText>
              )}
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="description_EN"
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.description_EN}
                  fullWidth
                  type="text"
                  label={t('formFields.description_eng')}
                  multiline
                  rows={6}
                  disabled={isPreview}
                />
              )}
            />
            {errors.description_EN &&
              errors.description_EN.type === 'required' && (
                <ErrorText>{t('formErrors.descriptionRequired')}</ErrorText>
              )}
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <FormLabel id="type-label">{t('formFields.type')}</FormLabel>
              <Controller
                name="type"
                control={control}
                rules={{ required: 'Type Required' }}
                render={({ field: { onChange, value } }) => (
                  <RadioGroup
                    aria-labelledby="type-label"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    row
                  >
                    <FormControlLabel
                      value="background"
                      control={<Radio />}
                      label={t('formFields.background')}
                      disabled={isPreview}
                    />
                    <FormControlLabel
                      value="push"
                      control={<Radio />}
                      label={t('formFields.push')}
                      disabled={isPreview}
                    />
                  </RadioGroup>
                )}
                defaultValue="background"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel id="target-label">{t('formFields.target')}</FormLabel>
              <Controller
                name="targetType"
                control={control}
                rules={{ required: 'Type Required' }}
                defaultValue="organisation"
                render={({ field: { value } }) => (
                  <RadioGroup
                    aria-labelledby="target-label"
                    value={value}
                    onChange={(e, value) =>
                      handleTargetTypeChange(value as NotificationTargetType)
                    }
                    row
                  >
                    <FormControlLabel
                      value="organisation"
                      control={<Radio />}
                      label={t('formFields.org')}
                      disabled={isPreview}
                    />
                    <FormControlLabel
                      value="group"
                      control={<Radio />}
                      label={t('formFields.group')}
                      disabled={isPreview}
                    />
                    <FormControlLabel
                      value="user"
                      control={<Radio />}
                      label={t('formFields.user')}
                      disabled={isPreview}
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            {(watchTarget === 'organisation' || watchTarget === 'group') &&
              organisations.length > 0 && (
                <Grid item xs={6}>
                  <Select
                    style={{
                      width: '100%',
                      marginBottom: '20px',
                    }}
                    onChange={(e) => {
                      handleOrgChange(
                        Array.isArray(e.target.value)
                          ? e.target.value[0]
                          : e.target.value,
                      );
                    }}
                    value={selectedOrganisation}
                    disabled={isPreview}
                  >
                    {orgMenuItems}
                  </Select>
                </Grid>
              )}

            {watchTarget === 'group' && groups.length > 0 && !isPreview && (
              <Grid item xs={6}>
                <Select
                  style={{
                    width: '100%',
                  }}
                  multiple
                  value={selectedGroups || []}
                  onChange={(event) =>
                    dispatch({
                      type: 'SET_SELECTED_GROUPS',
                      payload: Array.from(event.target.value),
                    })
                  }
                  disabled={isPreview}
                >
                  {groupMenuItems}
                </Select>
              </Grid>
            )}

            {watchTarget === 'user' && users && users.length > 0 && (
              <Grid item xs={6}>
                <Select
                  style={{
                    width: '100%',
                  }}
                  multiple
                  value={selectedUsers || []}
                  onChange={(event) =>
                    dispatch({
                      type: 'SET_SELECTED_USERS',
                      payload: Array.from(event.target.value),
                    })
                  }
                  disabled={isPreview}
                >
                  {userMenuItems}
                </Select>
              </Grid>
            )}
          </Grid>

          {isPreview && notification?.targets?.length && (
            <Grid item xs={12}>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontSize: 16,
                    color: theme.palette.grey[600],
                    marginBottom: '10px',
                  }}
                >
                  {t('createNotificationForm.selectedTargets')}
                </Typography>
                <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
                  <List dense>{renderTargets()}</List>
                </Box>
              </Grid>
            </Grid>
          )}

          {!isPreview && (
            <Grid item xs={12}>
              <Grid item xs={6}>
                <FormControl>
                  <FormLabel id="schedule-type-label">
                    {t('createNotificationForm.scheduleType')}
                  </FormLabel>
                  <RadioGroup
                    value={scheduleType}
                    onChange={(e, value) =>
                      dispatch({
                        type: 'SET_SCHEDULE_TYPE',
                        payload: value as ScheduleType,
                      })
                    }
                    aria-labelledby="schedule-type-label"
                    row
                  >
                    <FormControlLabel
                      value="instant"
                      control={<Radio />}
                      label={t('createNotificationForm.immediate')}
                      disabled={isPreview}
                    />
                    <FormControlLabel
                      value="scheduled"
                      control={<Radio />}
                      label={t('createNotificationForm.scheduled')}
                      disabled={isPreview}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          )}

          {(isPreview || scheduleType === 'scheduled') && (
            <Grid item xs={6}>
              <Typography
                style={{
                  fontSize: 16,
                  color: theme.palette.grey[600],
                  marginBottom: '10px',
                }}
              >
                {isPreview ? 'Sent at' : 'Send at'}
              </Typography>
              <Controller
                control={control}
                name="timestamp"
                render={({ field: { value, onChange } }) => (
                  <DateTimePicker
                    defaultValue={new Date().toISOString()}
                    value={value}
                    onChange={(date: Date | null) => {
                      if (date) {
                        onChange(date.toISOString());
                      }
                    }}
                    minDate={!isPreview ? new Date() : undefined}
                    disabled={isPreview}
                    minDetail="year"
                    disableClock
                    clearIcon={null}
                    format="dd/MM/yyyy HH:mm"
                  />
                )}
              />
            </Grid>
          )}
        </Grid>

        {!isPreview && (
          <Button
            variant="contained"
            sx={{ marginTop: '20px' }}
            disabled={!isValid || !isTargetValid}
            onClick={() =>
              dispatch({ type: 'SET_IS_MODAL_OPEN', payload: true })
            }
          >
            {t('formFields.submitButtonLabel')}
          </Button>
        )}
      </CustomForm>
      <Popup
        type="confirm"
        open={isModalOpen}
        onConfirm={handleSubmit(onSubmit)}
        handleClose={() =>
          dispatch({ type: 'SET_IS_MODAL_OPEN', payload: false })
        }
      />
    </Card>
  );
}
