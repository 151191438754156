import { Button, Card, Checkbox, Container, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, } from '@mui/material';
import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PageHeader from 'components/PageHeader/PageHeader';
import Popup from 'components/Popup/Popup';
import MoreMenu from 'components/table/MoreMenu';
import SearchNotFound from 'components/table/SearchNoFound';
import TableHeader from 'components/table/TableHeader';
import TableToolBar from 'components/table/TableToolbar';
import { getImpactReportMaintenanceDataTableHead } from 'constans/table-headers';
import SnackbarContext from 'contexts/snackbarContext';
import { deleteImpactReportMaintenanceData, deleteImpactReportMaintenanceDataBulk, getAllImpactReportMaintenanceData, searchImpactReportMaintenanceData, updateImpactReportMaintenanceDatabase } from 'services/impactReportMaintenanceData.services';
import { handleClick, handleSelectAllClick, sortByColumn, } from 'services/list.service';
import { ImpactReportMaintenanceDataType } from 'types/ImpactReportMaintenanceData.type';

export default function impactReportMaintenanceDataPage() {
  const {t,i18n: { language },} = useTranslation();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [selected, setSelected] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState('measurementDate');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { handleOpen } = useContext(SnackbarContext);
  const [impactReportMaintenanceDataList, setImpactReportMaintenanceDataList] = useState<ImpactReportMaintenanceDataType[]>([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    getImpactReportDistancesHandler();
  }, []);

  const getImpactReportDistancesHandler = () => {
    getAllImpactReportMaintenanceData().then((response) => {
      setImpactReportMaintenanceDataList(response.data);
    });
  };

  const searchFilter = useCallback(
    debounce((filterName) => {
      if (filterName) {
        searchImpactReportMaintenanceData(filterName).then((response) => {
          setImpactReportMaintenanceDataList(response.data);
        });
      } else {
        getImpactReportDistancesHandler();
      }
    }, 300),
    [],
  );

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteImpactReportMaintenanceData = (id: string) => {
    deleteImpactReportMaintenanceData(id)
      .then(() => {
        setImpactReportMaintenanceDataList([...impactReportMaintenanceDataList].filter(({ id }) => id !== id));
        handleSelectAllClick(false, impactReportMaintenanceDataList);
        setOpenModal(false);
        handleOpen(t('impactReportDistancePage.impactReportDistanceDeletedSuccessMessage'), 'success');
      })
      .catch(({ data }) => {
        handleOpen(data.message);
      });
  };

  const handleDeleteImpactReportMaintenanceDataBulk = (ids: string[]) => {
    deleteImpactReportMaintenanceDataBulk(ids)
      .then(() => {
        setImpactReportMaintenanceDataList(impactReportMaintenanceDataList.filter(({ id }) => !ids.includes(id)));
        handleSelectAllClick(false, impactReportMaintenanceDataList);
        setOpenModal(false);
        handleOpen(t('impactReportDistancePage.impactReportDistancesDeletedSuccessMessage'), 'success');
      })
      .catch(({ data }) => {
        handleOpen(data.message);
      });
  };

  const deleteImpactReportMaintenanceDataAction = () => {
    if (selected.length > 1) {
      handleDeleteImpactReportMaintenanceDataBulk(selected);
    } else {
      handleDeleteImpactReportMaintenanceData(selected[0]);
    }
  };

  const openModalHandler = (id?: string) => {
    setOpenModal(true);
    if (id) {
      setSelected([id]);
    }
  };

  
  const handleMyClick = () => {
    
    updateImpactReportMaintenanceDatabase().then((response) => {
      setImpactReportMaintenanceDataList(response.data);
    });

  };

  const sortedMaintenanceDataList = sortByColumn(
    impactReportMaintenanceDataList,
    orderBy as keyof ImpactReportMaintenanceDataType,
    order,
  );

  const impactReportMaintenanceDataTableHead = useMemo(getImpactReportMaintenanceDataTableHead, [language]);

  return (
    <Container>
      <PageHeader title={t('impactReportMaintenanceDataPage.title')} /*buttonTitle={t('impactReportMaintenanceDataPage.buttonTitle').toString()}*/ hasButton={false} hasBackButton/>

      <Button variant="outlined" sx={{ margin: '0px 5px 20px 5px' }} onClick={() => handleMyClick()}>         {t('impactReportMaintenanceDataPage.updateImpactReportMaintenanceDataButtonTitle')}</Button>

      <Card>
        <TableToolBar numSelected={selected.length} onFilterName={searchFilter} deleteHandler={() => openModalHandler()}/>

        <TableContainer>
          <Table>
            {
              <TableHeader
                order={order}
                orderBy={orderBy}
                headLabel={impactReportMaintenanceDataTableHead}
                rowCount={impactReportMaintenanceDataList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={(checked) =>
                  setSelected(handleSelectAllClick(checked, impactReportMaintenanceDataList))
                }
              />
            }
            <TableBody>
              {sortedMaintenanceDataList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const {
                    id,
                    compobotNumber,
                    measurementDate,
                    measurementWeight,
                    organisation,
                    location 
                  } = row;
                  const isItemSelected = selected.indexOf(id) !== -1;

                  return (
                    <TableRow
                      hover
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >

                      <TableCell padding="checkbox"><Checkbox checked={isItemSelected}onClick={() => setSelected(handleClick(id, selected))}/></TableCell>
                      <TableCell align="left">{compobotNumber}</TableCell>
                      <TableCell align="left">{ new Date(measurementDate).toLocaleDateString('hu-HU').replaceAll(' ', '')  }</TableCell>
                      <TableCell align="left">{measurementWeight}</TableCell>
                      <TableCell align="left">{organisation != null ? organisation.companyName : '!'}</TableCell>
                      <TableCell align="left">{location}</TableCell>
                      <TableCell align="right"><MoreMenu onDelete={() => openModalHandler(id)}id={id}/></TableCell>

                    </TableRow>
                  );
                })}
            </TableBody>
            {!impactReportMaintenanceDataList.length && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={impactReportMaintenanceDataList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, page) => setPage(page)}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('pagination.rowsPerPage')}
        />
      </Card>
      <Popup
        type="delete"
        open={openModal}
        onConfirm={deleteImpactReportMaintenanceDataAction}
        handleClose={() => setOpenModal(false)}
      />
    </Container>
  );
}
