import { CreateImpactReportType, ImpactReportType } from 'types/impactReport.type';
import { ApiClient } from './interceptor';

const api = ApiClient.getInstance();

export const getImpactReports = async () => {
  return await api.get('impact-report');
};



export const searchImpactReports = async (searchTerm: string) => {
  return await api.get(`impact-report/lookup/${searchTerm}`);
};



export const getImpactReportById = async (id: string): Promise<ImpactReportType> => {
  return await api
    .get(`impact-report/${id}`)
    .then((res) => res.data)
    .catch((err) => err);
};

export const deleteImpactReport = async (userId: string) => {
  return await api.delete(`impact-report/${userId}`);
};

export const deleteImpactReportBulk = async (userId: string[]) => {
  return await api.deleteWithParams('impact-report', { UIds: userId });
};

export const updateImpactReport = async (id: string, data: CreateImpactReportType) => {
  return await api.put(`impact-report/update/${id}`, data);
};

export const createImpactReport = async (impactReport: CreateImpactReportType,) => {
  return await api.post('impact-report/generateImpactReport', impactReport);
};

export const modifyImpactReport = async (id: string, impactReport: CreateImpactReportType,) => {
  return await api.put(`impact-report/${id}`, impactReport);
};