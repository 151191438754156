/* eslint-disable indent */
import {
  Card,
  Checkbox,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PageHeader from 'components/PageHeader/PageHeader';
import Popup from 'components/Popup/Popup';
import MoreMenu from 'components/table/MoreMenu';
import SearchNotFound from 'components/table/SearchNoFound';
import TableHeader from 'components/table/TableHeader';
import TableToolBar from 'components/table/TableToolbar';
import { getNotificationsTableHead } from 'constans/table-headers';
import SnackbarContext from 'contexts/snackbarContext';
import {
  handleClick,
  handleSelectAllClick,
  sortByColumn,
} from 'services/list.service';
import {
  deleteNotification,
  deleteNotificationBulk,
  getAllNotifications,
  getAllNotificationsBasicData
} from 'services/notification.service';
import { NotificationType } from 'types/notification.type';

type Order = 'asc' | 'desc';

export default function NotificationsPage() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<Order>('desc');
  const [orderByColumn, setOrderByColumn] = useState('timestamp');
  const [selected, setSelected] = useState<string[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { handleOpen } = useContext(SnackbarContext);
  const [notificationList, setNotificationList] = useState<
    NotificationType[] | []
  >([]);
  const [isSearch] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const getNotificationsHandler = async () => {
      //const notifications = await getAllNotifications();
      const notifications = await getAllNotificationsBasicData();

      if (notifications) {
        setNotificationList(notifications);
      }
    };

    getNotificationsHandler();
  }, []);

  const searchFilter = () => undefined;
  // const searchFilter = useCallback(
  //   debounce((filterName) => {
  //     if (filterName) {
  //       searchNotifications(filterName).then((response) => {
  //         setNotificationList(response.data);
  //       });
  //     } else {
  //       getNotificationsHandler();
  //     }
  //   }, 300),
  //   [],
  // );

  const handleRequestSort = (property: string) => {
    const isAsc = orderByColumn === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderByColumn(property);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openModalHandler = (id?: string) => {
    setOpenModal(true);
    if (id) {
      setSelected([id]);
    }
  };

  const handleDeleteNotification = () => {
    deleteNotification(selected[0])
      .then(() => {
        setNotificationList((prev) => prev.filter((n) => n.id !== selected[0]));
        setOpenModal(false);
        setSelected(handleSelectAllClick(false, notificationList));
        handleOpen(
          t('notificationsPage.notificationDeletedMessage'),
          'success',
        );
      })
      .catch(() => {
        handleOpen(
          t('notificationsPage.notificationDeletedErrorMessage'),
          'error',
        );
      });
  };

  const handleDeleteNotificationsBulk = (notiIds: string[]) => {
    deleteNotificationBulk(notiIds)
      .then(() => {
        const newList = notificationList.filter(
          ({ id }) => !notiIds.includes(id),
        );
        setNotificationList(newList);
        handleSelectAllClick(false, notificationList);
        setOpenModal(false);
        handleOpen(
          t('notificationsPage.notificationsDeletedMessage'),
          'success',
        );
      })
      .catch(({ data }) => {
        handleOpen(data.message);
      });
  };

  const deleteNotificationAction = () => {
    if (selected.length > 1) {
      handleDeleteNotificationsBulk(selected);
    } else {
      handleDeleteNotification();
    }
  };

  const sortedNotificationList = sortByColumn(
    notificationList,
    orderByColumn as keyof NotificationType,
    order,
  );

  const notificationsTableHead = useMemo(getNotificationsTableHead, [language]);

  return (
    <Container>
      <PageHeader
        title={t('notificationsPage.title')}
        hasButton
        buttonTitle={t('notificationsPage.buttonTitle').toString()}
      />
      {sortedNotificationList.length ? (
        <Card>
          <TableToolBar
            numSelected={selected.length}
            onFilterName={searchFilter}
            deleteHandler={() => openModalHandler()}
          />

          <TableContainer>
            <Table>
              <TableHeader
                order={order}
                orderBy={orderByColumn}
                headLabel={notificationsTableHead}
                rowCount={notificationList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={(checked) =>
                  setSelected(handleSelectAllClick(checked, notificationList))
                }
              />
              <TableBody>
                {sortedNotificationList
                  .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .map(
                    ({
                      id,
                      title_HU,
                      title_EN,
                      description_HU,
                      description_EN,
                      type,
                      timestamp,
                      targetType,
                      numberOfTargets,
                    }) => {
                      const isItemSelected = selected.includes(id);
                      const sentAt = new Date(timestamp).toLocaleString([], {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                      });
                      const isDelivered =
                        Date.now() > new Date(timestamp).getTime();

                      const title = language === 'en' ? title_EN : title_HU;

                      const description =
                        language === 'en' ? description_EN : description_HU;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onClick={() =>
                                setSelected(handleClick(id, selected))
                              }
                            />
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                variant="subtitle2"
                                noWrap
                                sx={{ fontWeight: 600 }}
                              >
                                {title ?? ''}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            {description
                              ? `${
                                  description.length > 30
                                    ? description.slice(0, 30) + '...'
                                    : description
                                }`
                              : ''}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ textTransform: 'capitalize' }}
                          >
                            {type}
                          </TableCell>
                          <TableCell align="left">{sentAt}</TableCell>
                          <TableCell
                            align="left"
                            sx={{ textTransform: 'capitalize' }}
                          >
                            {isDelivered ? 'Delivered' : 'Not delivered'}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ textTransform: 'capitalize' }}
                          >
                            {targetType ?? ''}
                          </TableCell>
                          <TableCell align="left">{numberOfTargets}</TableCell>
                          <TableCell align="right">
                            <MoreMenu
                              onDelete={() => openModalHandler(id)}
                              id={id}
                              isNotification
                              isNotificationDelivered={isDelivered}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    },
                  )}
              </TableBody>

              {!sortedNotificationList.length && isSearch && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={notificationList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      ) : (
        <Container style={{ padding: 0 }}>
          {t('notificationsPage.noData')}
        </Container>
      )}
      <Popup
        type="delete"
        open={openModal}
        onConfirm={deleteNotificationAction}
        handleClose={() => setOpenModal(false)}
      />
    </Container>
  );
}
