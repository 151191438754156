import { ApiClient } from './interceptor';

const api = ApiClient.getInstance();

export const getQuizLogs = async () => {
  return await api.get('quiz-log');
};


export const getQuizResults = async (startDate?: Date, endDate?: Date) => {
  const params = new URLSearchParams();

  if (startDate) params.append('startDate', startDate.toISOString());
  if (endDate) params.append('endDate', endDate.toISOString());

  return await api.get('quiz-log/results', params);
};

export const getQuizLogsByQuizId = async ({ quizId }: { quizId: string }) => {
  return await api.get(`quiz-log/quiz/${quizId}`);
};
