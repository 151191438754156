import { CreateImpactReportDistanceType, ImpactReportDistanceType } from 'types/ImpactReportDistance.type';
import { ApiClient } from './interceptor';

const api = ApiClient.getInstance();

export const getImpactReportDistances = async () => {
  return await api.get('impact-report-distance');
};

export const getImpactReportDistanceById = async (id: string): Promise<ImpactReportDistanceType> => {
  return await api
    .get(`impact-report-distance/${id}`)
    .then((res) => res.data)
    .catch((err) => err);
};

export const deleteImpactReportDistance = async (ids: string) => {
  return await api.delete(`impact-report-distance/${ids}`);
};

export const deleteImpactReportDistanceBulk = async (ids: string[]) => {
  return await api.deleteWithParams('impact-report-distance', { UIds: ids });
};

export const createImpactReportDistance = async (impactReportDistance: CreateImpactReportDistanceType,) => {
  return await api.post('impact-report-distance', impactReportDistance);
};

export const modifyImpactReportDistance = async (id: string, impactReportDistance: ImpactReportDistanceType,) => {
  return await api.put(`impact-report-distance/${id}`, impactReportDistance);
};

export const searchImpactReportDistances = async (searchTerm: string) => {
  return await api.get(`impact-report-distance/lookup/${searchTerm}`);
};
