import { colors } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { theme } from 'theme';
import { AnswerType, QuestionType, QuizAnswerLogType } from 'types/quizes.type';

const QuizQuestionStatistics = ({
  question,
  answerLogs,
}: {
  question: QuestionType;
  answerLogs: QuizAnswerLogType[];
}) => {
  const {
    i18n: { language },
  } = useTranslation();
  const selectedLanguage = language === 'hu' ? 'text_HU' : 'text_EN';

  return (
    <div>
      <h3>{question[selectedLanguage]}</h3>
      {question.answers.map((answer: AnswerType, index: number) => {
        const countAswers = answerLogs.filter(
          (answerLog) => answerLog.quizAnswerId === answer.id,
        ).length;
        return (
          <div
            key={index}
            style={{
              border: `1px solid ${colors.grey[400]}`,
              paddingLeft: '15px',
              marginBottom: '5px',
              display: 'flex',
              borderRadius: '8px',
              backgroundColor: answer.isCorrect
                ? theme.palette.primary.main
                : '',
            }}
          >
            <p>
              {answer[selectedLanguage]} ({countAswers})
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default QuizQuestionStatistics;
