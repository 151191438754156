import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
} from '@mui/material';
import * as QRCode from 'qrcode';
import { CompobotType } from '../../types/compobots.type';

interface QrCodeDialogProps {
  open: boolean;
  onClose: () => void;
  compobot: CompobotType | null;
}

const QrCodeDialog: React.FC<QrCodeDialogProps> = ({ open, onClose, compobot }) => {
  const [deviceId, setDeviceId] = useState('');
  const [date, setDate] = useState('');
  const [score, setScore] = useState('200');
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (compobot) {
      setDeviceId(compobot.deviceId);
      setDate(new Date().toISOString().replace(/[-:]/g, '').slice(0, 15));
      generateQRCode();
    }
  }, [compobot, open]);

  const generateQRCode = () => {
    if (canvasRef.current) {
      const content = `compocityplus://qr?content=${deviceId};${date};${score}`;
      QRCode.toCanvas(canvasRef.current, content, (error) => {
        if (error) console.error('Error generating QR code:', error);
      });
    }
  };

  const updateQrCode = () => {
    generateQRCode();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>QR Code for {compobot?.deviceId || 'Compobot'}</DialogTitle>
      <DialogContent>
        {compobot ? (
          <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
            <canvas ref={canvasRef} />
            <TextField
              label="Device ID"
              value={deviceId}
              onChange={(e) => setDeviceId(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Score"
              value={score}
              onChange={(e) => setScore(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Button onClick={updateQrCode} variant="contained" color="primary" sx={{ mt: 2 }}>
              Update QR Code
            </Button>
          </Box>
        ) : (
          <Typography>No compobot selected</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default QrCodeDialog;
