import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import CreateImpactReportModelForm from 'components/CreateImpactReportModelForm/CreateImpactReportModelForm';
import PageHeader from 'components/PageHeader/PageHeader';

export default function CreateImpactReportModelPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  
  const impactReportModelId = id || '';

  return (
    <Container>
      <PageHeader title={id ? t('createImpactReportModelPage.titleModify') : t('createImpactReportModelPage.titleCreate')} hasButton={false} hasBackButton={true} />
      <CreateImpactReportModelForm  impactReportModelId={impactReportModelId} />
    </Container>
  );
}
