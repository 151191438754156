import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ListIcon from '@mui/icons-material/List';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PinIcon from '@mui/icons-material/Pin';
import PreviewIcon from '@mui/icons-material/Preview';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';

import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

type UserMoreMenuProps = {
  onDelete?: VoidFunction;
  onChangePin?: VoidFunction;
  onPreview?: VoidFunction;
  viewResults?: VoidFunction;
  viewStatistics?: VoidFunction;
  id?: string;
  groups?: boolean;
  hasPin?: boolean;
  isNotification?: boolean;
  isNotificationDelivered?: boolean;
  hideEdit?: boolean;
  editUrl?: string;
};

export default function MoreMenu({
  onDelete,
  onChangePin,
  onPreview,
  viewResults,
  viewStatistics,
  id,
  groups,
  hasPin,
  isNotification,
  isNotificationDelivered,
  hideEdit,
  editUrl,
}: UserMoreMenuProps) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {!hideEdit && (
          <MenuItem
            component={RouterLink}
            to={editUrl ?? `${id}`}
            sx={{ color: 'text.secondary' }}
          >
            <ListItemIcon>
              {isNotification && isNotificationDelivered ? (
                <PreviewIcon />
              ) : (
                <EditIcon />
              )}
            </ListItemIcon>
            <ListItemText
              primary={
                isNotification && isNotificationDelivered
                  ? t('moreMenu.view')
                  : t('moreMenu.edit')
              }
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        )}
        {onPreview && (
          <MenuItem onClick={onPreview} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <PreviewIcon />
            </ListItemIcon>
            <ListItemText
              primary={t('moreMenu.view')}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        )}
        {groups && (
          <MenuItem
            component={RouterLink}
            to={`${id}/groups`}
            sx={{ color: 'text.secondary' }}
          >
            <ListItemIcon>
              <ManageAccountsIcon />
            </ListItemIcon>
            <ListItemText
              primary={t('moreMenu.manageGroups')}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        )}
        {hasPin && (
          <MenuItem sx={{ color: 'text.secondary' }} onClick={onChangePin}>
            <ListItemIcon>
              <PinIcon />
            </ListItemIcon>
            <ListItemText
              primary="PIN"
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        )}
        {viewResults && (
          <MenuItem onClick={viewResults} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText
              primary={t('moreMenu.results')}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        )}
        {viewStatistics && (
          <MenuItem onClick={viewStatistics} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <StackedLineChartIcon />
            </ListItemIcon>
            <ListItemText
              primary={t('moreMenu.statistics')}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        )}
        {onDelete && (
          <MenuItem onClick={onDelete} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText
              primary={t('moreMenu.delete')}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
