/* eslint-disable indent */
import {
  Box,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PageHeader from 'components/PageHeader/PageHeader';
import SearchNotFound from 'components/table/SearchNoFound';
import TableHeader from 'components/table/TableHeader';
import TableToolbar from 'components/table/TableToolbar';
import { getOrganisationStatisticsTableHead } from 'constans/table-headers';
import DateTimePicker from 'react-datetime-picker';
import { handleSelectAllClick, sortByColumn } from 'services/list.service';
import {
  getOrganizationsStatistics,
  searchOrganisationsStatistics,
} from 'services/organisation.service';
import { OrganisationStatisticsType } from 'types/organisations.type';

export default function OrganisationsStatisticsPage() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [selected, setSelected] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [organisationList, setOrganisationStatisticsList] = useState<
    OrganisationStatisticsType[]
  >([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    getOrganizationsHandler();
  }, []);

  const getOrganizationsHandler = () => {
    getOrganizationsStatistics().then((response) => {
      setOrganisationStatisticsList(response.data);
    });
  };

  const searchFilter = useCallback(
    debounce((filterName) => {
      if (filterName) {
        searchOrganisationsStatistics(filterName).then((response) => {
          setOrganisationStatisticsList(response.data);
        });
      } else {
        getOrganizationsHandler();
      }
    }, 300),
    [],
  );

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedOrgs = sortByColumn(
    organisationList,
    orderBy as keyof OrganisationStatisticsType,
    order,
  );

  const orgStatTableHead = useMemo(getOrganisationStatisticsTableHead, [
    language,
  ]);

  return (
    <Container>
      <PageHeader
        title={t('organisationsStatisticsPage.title')}
        hasButton={false}
        hasBackButton={true}
      />

{/*      <Box
        style={{
          display: 'flex',
        }}
      >
        <DateTimePicker
          value={startDate}
          onChange={(date: Date | null) => {
            if (date) {
              setStartDate(date);
            }
          }}
          minDetail="year"
          maxDate={endDate}
          disableClock
          clearIcon={null}
          format="dd/MM/yyyy HH:mm"
        />
        <DateTimePicker
          value={endDate}
          onChange={(date: Date | null) => {
            if (date) {
              setEndDate(date);
            }
          }}
          minDate={startDate}
          minDetail="year"
          disableClock
          clearIcon={null}
          format="dd/MM/yyyy HH:mm"
        />
      </Box>*/}

      <Card>
        <TableToolbar
          numSelected={selected.length}
          onFilterName={searchFilter}
          deleteHandler={() => null}
        />

        <TableContainer>
          <Table>
            {
              <TableHeader
                order={order}
                orderBy={orderBy}
                headLabel={orgStatTableHead}
                rowCount={organisationList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={(checked) =>
                  setSelected(handleSelectAllClick(checked, organisationList))
                }
                disableSelect
              />
            }
            <TableBody>
              {sortedOrgs
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const {
                    id,
                    companyName,
                    users,
                    qrReads,
                    quizFills,
                    lastQRRead,
                    compobots,
                  } = row;

                  return (
                    <TableRow hover key={id} tabIndex={-1}>
                      <TableCell component="th" scope="row" padding="none">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography
                            variant="subtitle2"
                            noWrap
                            sx={{ fontWeight: 600 }}
                          >
                            {companyName}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="right">{compobots}</TableCell>
                      <TableCell align="right">{users}</TableCell>
                      <TableCell align="right">{qrReads}</TableCell>
                      <TableCell align="right">
                        {new Date(lastQRRead).getTime() ==
                        new Date('1970-01-01T00:00:00.000Z').getTime()
                          ? 'Nincs adat'
                          : new Date(lastQRRead)
                              .toLocaleDateString('hu-HU')
                              .replaceAll(' ', '') +
                            ' ' +
                            new Date(lastQRRead).toLocaleTimeString('hu-HU')}
                      </TableCell>
                      <TableCell align="right">{quizFills}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>

            {!organisationList.length && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={organisationList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, page) => setPage(page)}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('pagination.rowsPerPage')}
        />
      </Card>
    </Container>
  );
}
