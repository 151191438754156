import { Button, Card, Grid, styled, TextField } from '@mui/material';
import { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import SnackbarContext from 'contexts/snackbarContext';

import { createImpactReportDistance, getImpactReportDistanceById, modifyImpactReportDistance } from 'services/impactReportDistance.services';
import { CreateImpactReportDistanceType, ImpactReportDistanceType } from 'types/ImpactReportDistance.type';

type CreateImpactReportDistanceFormType = {
  distancefrom:string;
  distanceto:string;
  distance: number;
  typeoftravel: string;
  co2e: number;
};

const CustomForm = styled('form')`width: 100%;`;

interface CreateImpactReportDistanceFormProps {
  impactReportDistanceId?: string;
}

export default function CreateImpactReportDistanceForm({impactReportDistanceId:string} : CreateImpactReportDistanceFormProps) {
  const { t } = useTranslation();
  const {handleSubmit, formState: { errors }, setValue, control, watch,} = useForm<CreateImpactReportDistanceFormType>();
  const { handleOpen } = useContext(SnackbarContext);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      getImpactReportDistanceById(id).then((data) => {
        setValue('distancefrom', data.distancefrom);
        setValue('distanceto', data.distanceto);
        setValue('distance', data.distance);
        setValue('typeoftravel', data.typeoftravel);
        setValue('co2e', data.co2e);
      });
    }
  }, [id]);

  const onSubmit = (data: CreateImpactReportDistanceFormType) => {
    
    if (!id) {
      const createImpactReportDistanceObject: CreateImpactReportDistanceType = {
        distancefrom: data.distancefrom,
        distanceto: data.distanceto,
        distance: data.distance,
        typeoftravel: data.typeoftravel,
        co2e: data.co2e,
      };

      createImpactReportDistance(createImpactReportDistanceObject)
        .then((result) => {
          handleOpen(t('impactReportDistanceForm.impactReportDistanceCreatedSuccessfullyMessage'), 'success',);
          navigate('/impactReportDistances/'+result.data.id);
        })
        .catch(({ data }) => {
          handleOpen(data.message);
        });
    } else {

      const modifyImpactReportDistanceObject: ImpactReportDistanceType = {
        id: id,
        distancefrom: data.distancefrom,
        distanceto: data.distanceto,
        distance: data.distance,
        typeoftravel: data.typeoftravel,
        co2e: data.co2e,
      };

      modifyImpactReportDistance(id, modifyImpactReportDistanceObject)
        .then((result) => {
          handleOpen(t('impactReportDistanceForm.impactReportDistanceModifiedSuccessfullyMessage'), 'success',);

          getImpactReportDistanceById(id).then((data) => {
            setValue('distancefrom', data.distancefrom);
            setValue('distanceto', data.distanceto);
            setValue('distance', data.distance);
            setValue('typeoftravel', data.typeoftravel);
            setValue('co2e', data.co2e);
          });
          
        })
        .catch(({ data }) => {
          handleOpen(data.message);
        });
    }
  };

  const checkKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  return (
    <Card sx={{ padding: '40px', maxWidth: '800px' }}>
      <CustomForm onSubmit={handleSubmit(onSubmit)} onKeyDown={checkKeyDown}>
        <Grid container spacing={2}>
          <Grid item xs={12}><Controller control={control} defaultValue=""  name="distancefrom" render={({ field }) => ( <TextField {...field} fullWidth label={t('formFields.distancefrom')} type="text" inputProps={{ maxLength: 30 }} /> )} /></Grid>          
          <Grid item xs={12}><Controller control={control} defaultValue=""  name="distanceto" render={({ field: { value, onChange } }) => (<TextField onChange={onChange} fullWidth variant="outlined" type="text" value={value} label={t('formFields.distanceto')} />)}/></Grid>
          <Grid item xs={12}><Controller control={control} defaultValue={0} name="distance" render={({ field: { value, onChange } }) => (<TextField onChange={onChange} fullWidth variant="outlined" type="text" value={value} label={t('formFields.distance')} />)}/></Grid>
          <Grid item xs={12}><Controller control={control} defaultValue=""  name="typeoftravel" render={({ field: { value, onChange } }) => (<TextField onChange={onChange} fullWidth variant="outlined" type="text" value={value} label={t('formFields.typeoftravel')} />)}/></Grid>
          <Grid item xs={12}><Controller control={control} defaultValue={0} name="co2e" render={({ field: { value, onChange } }) => (<TextField onChange={onChange} fullWidth variant="outlined" type="text" value={value} label={t('formFields.co2e')} />)}/></Grid>
        </Grid>

        <Button variant="contained" sx={{ marginTop: '20px' }} type="submit">{t('formFields.submitButtonLabel')}</Button>
      </CustomForm>
    </Card>
  );
}
