import {
  Button,
  Card,
  Checkbox,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PageHeader from 'components/PageHeader/PageHeader';
import Popup from 'components/Popup/Popup';
import MoreMenu from 'components/table/MoreMenu';
import { Link, useNavigate } from 'react-router-dom';
import SearchNotFound from 'components/table/SearchNoFound';
import TableHeader from 'components/table/TableHeader';
import TableToolbar from 'components/table/TableToolbar';
import { getCompobotsTableHead } from 'constans/table-headers';
import SnackbarContext from 'contexts/snackbarContext';
import {
  deleteCompobot,
  deleteCompobotBulk,
  getCompobots,
  searchCompobots,
} from 'services/compobot.service';
import { sortByColumn } from 'services/list.service';
import { CompobotType } from 'types/compobots.type';
import QrCodeDialog from './QrCodeDialog';

export default function CompoBotsPage() {
  const navigate = useNavigate();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [selected, setSelected] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { handleOpen } = useContext(SnackbarContext);
  const [compobotList, setCompobotList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [qrDialogOpen, setQrDialogOpen] = useState(false);
  const [selectedCompobot, setSelectedCompobot] = useState<CompobotType | null>(null);

  useEffect(() => {
    getCompobotsHandler();
  }, []);

  const getCompobotsHandler = () => {
    getCompobots().then((response) => {
      setCompobotList(response.data);
    });
  };

  const searchFilter = useCallback(
    debounce((filterName) => {
      if (filterName) {
        searchCompobots(filterName).then((response) => {
          setCompobotList(response.data);
        });
      } else {
        getCompobotsHandler();
      }
    }, 300),
    [],
  );

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked: boolean) => {
    if (checked) {
      const newSelecteds = compobotList.map((n: CompobotType) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteCompobot = (id: string) => {
    deleteCompobot(id)
      .then(() => {
        handleSelectAllClick(false);
        setOpenModal(false);
        setCompobotList((list) =>
          list.filter((compobot: CompobotType) => compobot.id !== id),
        );
        handleOpen(t('compobotsPage.deleteSuccessMessage'), 'success');
      })
      .catch(({ data }) => {
        handleOpen(data.message);
      });
  };

  const handleDeleteCompobotBulk = (ids: string[]) => {
    deleteCompobotBulk(ids)
      .then(() => {
        setCompobotList(compobotList.filter(({ id }) => !ids.includes(id)));
        handleSelectAllClick(false);
        setOpenModal(false);
        handleOpen(t('compobotsPage.bulkDeleteSuccessMessage'), 'success');
      })
      .catch(({ data }) => {
        handleOpen(data.message);
      });
  };

  const deleteCompobotAction = () => {
    if (selected.length > 1) {
      handleDeleteCompobotBulk(selected);
    } else {
      handleDeleteCompobot(selected[0]);
    }
  };

  const openModalHandler = (id?: string) => {
    setOpenModal(true);
    if (id) {
      setSelected([id]);
    }
  };

  const sortedCompobots = sortByColumn(
    compobotList,
    orderBy as keyof CompobotType,
    order,
  );

  const compobotsTableHead = useMemo(getCompobotsTableHead, [language]);

  return (
    <Container>
      <PageHeader
        title={t('compobotsPage.title')}
        buttonTitle={t('compobotsPage.buttonTitle').toString()}
        hasButton
      />
      <Card>
        <TableToolbar
          numSelected={selected.length}
          onFilterName={searchFilter}
          deleteHandler={() => openModalHandler()}
        />

        <TableContainer>
          <Table>
            {
              <TableHeader
                order={order}
                orderBy={orderBy}
                headLabel={compobotsTableHead}
                rowCount={compobotList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
            }
            <TableBody>
              {sortedCompobots
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const { id, deviceId, organisation }: CompobotType = row;
                  const isItemSelected = selected.indexOf(id) !== -1;

                  return (
                    <TableRow
                      hover
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onClick={() => handleClick(id)}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography
                            variant="subtitle2"
                            noWrap
                            sx={{ fontWeight: 600 }}
                          >
                            {deviceId}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="left">
                        {organisation?.companyName}
                      </TableCell>
                      <TableCell align="left">{organisation?.score}</TableCell>

                      <TableCell align="right">
                        <Stack direction="row" spacing={1} justifyContent="flex-end">
                          <MoreMenu
                            onDelete={() => openModalHandler(id)}
                            id={id}
                          />
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => navigate(`/compobots/logs?deviceId=${deviceId}`)}
                          >
                            {t('compobotsPage.logs')}
                          </Button>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => navigate(`/compobots/telemetry?deviceId=${deviceId}`)}
                          >
                            {t('compobotsPage.telemetry')}
                          </Button>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              setSelectedCompobot(row);
                              setQrDialogOpen(true);
                            }}
                          >
                            QR
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            {!compobotList.length && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={compobotList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, page) => setPage(page)}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('pagination.rowsPerPage')}
        />
      </Card>
      <Link
        to="/compobots/logs"
        style={{ margin: '1rem', display: 'inline-block', textDecoration: 'none' }}
      >
        {t('compobotsPage.viewAllLogs')}
      </Link>
      <Popup
        type="delete"
        open={openModal}
        onConfirm={deleteCompobotAction}
        handleClose={() => setOpenModal(false)}
      />
      <QrCodeDialog
        open={qrDialogOpen}
        onClose={() => setQrDialogOpen(false)}
        compobot={selectedCompobot}
      />
    </Container>
  );
}
