import { CreateImpactReportModelType, ImpactReportModelType } from 'types/impactReportModel.type';
import { ApiClient } from './interceptor';

const api = ApiClient.getInstance();

export const getImpactReportModels = async () => {
  return await api.get('impact-report-model');
};

export const getImpactReportModelById = async (id: string): Promise<ImpactReportModelType> => {
  return await api
    .get(`impact-report-model/${id}`)
    .then((res) => res.data)
    .catch((err) => err);
};

export const deleteImpactReportModel = async (userId: string) => {
  return await api.delete(`impact-report-model/${userId}`);
};

export const deleteImpactReportModelBulk = async (userId: string[]) => {
  return await api.deleteWithParams('impact-report-model', { UIds: userId });
};

export const createImpactReportModel = async (impactReport: CreateImpactReportModelType,) => {
  return await api.post('impact-report-model', impactReport);
};

export const modifyImpactReportModel = async (id: string, impactReport: ImpactReportModelType,) => {
  return await api.put(`impact-report-model/${id}`, impactReport);
};

export const searchImpactReportModels = async (searchTerm: string) => {
  return await api.get(`impact-report-model/lookup/${searchTerm}`);
};