import { Button, Card, FormControl, Grid, InputLabel, MenuItem, Select, styled, TextField } from '@mui/material';
import { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import SnackbarContext from 'contexts/snackbarContext';
import { createImpactReportDistanceOverride, getImpactReportDistanceOverrideById, modifyImpactReportDistanceOverride } from 'services/impactReportDistanceOverride.services';

import { CreateImpactReportDistanceOverrideType, ModifyImpactReportDistanceOverrideType } from 'types/ImpactReportDistanceOverride.type';
import { OrganisationType } from 'types/organisations.type';

type CreateImpactReportDistanceOverrideFormType = {
  distanceFrom: string;
  distanceTo: string;
  distance: number;
  typeoftravel: string;
  co2e: number;
  organisation: string;
};

const CustomForm = styled('form')`width: 100%;`;

interface CreateImpactReportDistanceOverrideFormProps {
  impactReportDistanceOverrideId?: string;
  organisationList: OrganisationType[];
}

export default function CreateImpactReportDistanceOverrideForm({impactReportDistanceOverrideId, organisationList} : CreateImpactReportDistanceOverrideFormProps) {
  const { t } = useTranslation();
  const {handleSubmit, formState: { errors }, setValue, control, watch,} = useForm<CreateImpactReportDistanceOverrideFormType>();
  const { handleOpen } = useContext(SnackbarContext);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      getImpactReportDistanceOverrideById(id).then((data) => {
        setValue('distanceFrom', data.distancefrom);
        setValue('distanceTo', data.distanceto);
        setValue('distance', data.distance);
        setValue('typeoftravel', data.typeoftravel);
        setValue('co2e', data.co2e);
        setValue('organisation', data.organisation.id);
      });
    }
  }, [id]);

  const onSubmit = (data: CreateImpactReportDistanceOverrideFormType) => {
    


    if (!id) {
      const impactReprotDistanceOverrideObject: CreateImpactReportDistanceOverrideType = {
        distancefrom: data.distanceFrom,
        distanceto: data.distanceTo,
        distance: data.distance,
        typeoftravel: data.typeoftravel,
        organisationId: data.organisation,
        co2e: data.co2e,
      };      
      createImpactReportDistanceOverride(impactReprotDistanceOverrideObject)
        .then((result) => {
          navigate('/impactReportDistanceOverrides/'+result.data.id);
          handleOpen(t('impactReportDistanceOverrideForm.impactReportDistanceOverrideCreatedSuccessfullyMessage'), 'success',);
        })
        .catch(({ data }) => {
          handleOpen(data.message);
        });
    }
    else {

      const modifyImpactReportDistanceOverrideObject: ModifyImpactReportDistanceOverrideType = {
        id: id,
        distancefrom: data.distanceFrom,
        distanceto: data.distanceTo,
        distance: data.distance,
        typeoftravel: data.typeoftravel,
        organisationId: data.organisation,
        co2e: data.co2e,
      };

      modifyImpactReportDistanceOverride(id, modifyImpactReportDistanceOverrideObject)
        .then((result) => {
          handleOpen(t('impactReportTemplateForm.impactReportTemplateModifiedSuccessfullyMessage'), 'success',);

          getImpactReportDistanceOverrideById(id).then((data) => {
            setValue('distanceFrom', data.distancefrom);
            setValue('distanceTo', data.distanceto);
            setValue('distance', data.distance);
            setValue('typeoftravel', data.typeoftravel);
            setValue('co2e', data.co2e);
            setValue('organisation', data.organisation.id);
          });
          
        })
        .catch(({ data }) => {
          handleOpen(data.message);
        });

    }
  };

  const checkKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  return (
    <Card sx={{ padding: '40px', maxWidth: '800px' }}>
      <CustomForm onSubmit={handleSubmit(onSubmit)} onKeyDown={checkKeyDown}>
        <Grid container spacing={2}>

          <Grid item xs={6}><Controller control={control} defaultValue="" name="distanceFrom" render={({ field: { value, onChange } }) => (<TextField onChange={onChange} fullWidth variant="outlined" type="text" value={value} label={t('formFields.distancefrom')} />)}/></Grid>
          <Grid item xs={6}><Controller control={control} defaultValue="" name="distanceTo" render={({ field: { value, onChange } }) => (<TextField onChange={onChange} fullWidth variant="outlined" type="text" value={value} label={t('formFields.distanceto')} />)}/></Grid>
          <Grid item xs={4}><Controller control={control} defaultValue={0} name="distance" render={({ field: { value, onChange } }) => (<TextField onChange={onChange} fullWidth variant="outlined" type="text" value={value} label={t('formFields.distance')} />)}/></Grid>
          <Grid item xs={4}><Controller control={control} defaultValue="" name="typeoftravel" render={({ field: { value, onChange } }) => (<TextField onChange={onChange} fullWidth variant="outlined" type="text" value={value} label={t('formFields.typeOfTravel')} />)}/></Grid>
          <Grid item xs={4}><Controller control={control} defaultValue={0} name="co2e" render={({ field: { value, onChange } }) => (<TextField onChange={onChange} fullWidth variant="outlined" type="text" value={value} label={t('formFields.co2e')} />)}/></Grid>

          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="label">{t('formFields.organisation')}</InputLabel>
              <Controller
                name="organisation"
                defaultValue = ""
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select value={value} onChange={onChange} label={t('formFields.organisation')} labelId="label" error={errors.organisation ? true : false}>
                    <MenuItem></MenuItem>
                    {organisationList.map((organisation: OrganisationType) => {return (<MenuItem key={organisation?.id}value={organisation?.id}>{organisation?.companyName}</MenuItem>);})}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>

        </Grid>

        <Button variant="contained" sx={{ marginTop: '20px' }} type="submit">{t('formFields.submitButtonLabel')}</Button>
      </CustomForm>
    </Card>
  );
}
