import i18n from 'translations/i18n';
import { Stack, TableCell, Typography } from '@mui/material';
import React from 'react';

export const getOrganisationTableHead = () => [
  {
    id: 'companyName',
    label: i18n.t('tableHeaders.company'),
    alignRight: false,
  },
  {
    id: 'contactEmail',
    label: i18n.t('tableHeaders.contactEmail'),
    alignRight: false,
  },
  {
    id: 'emailHandler',
    label: i18n.t('tableHeaders.emailHandler'),
    alignRight: false,
  },
  { id: 'users', label: i18n.t('tableHeaders.users'), alignRight: false },
  { id: 'groups', label: i18n.t('tableHeaders.groups'), alignRight: false },
  { id: 'address', label: i18n.t('tableHeaders.address'), alignRight: false },
  {
    id: 'isOpen',
    label: i18n.t('organisationsPage.isOpen'),
    alignRight: false,
    numeric: false,
    boolean: true,
  },
  { id: '' },
];

export const getOrganisationStatisticsTableHead = () => [
  { id: 'companyName', label: i18n.t('tableHeaders.company'), alignRight: false, },
  { id: 'compobots', label: i18n.t('tableHeaders.compobots'), alignRight: true },
  { id: 'users', label: i18n.t('tableHeaders.users'), alignRight: true },
  { id: 'qrReads', label: i18n.t('tableHeaders.qrReads'), alignRight: true },
  { id: 'lastQRRead', label: i18n.t('tableHeaders.last'), alignRight: true },
  { id: 'quizFills', label: i18n.t('tableHeaders.quizFills'), alignRight: true },
  { id: '' },
];



export const getAdminsTableHead = () => [
  {
    id: 'displayName',
    label: i18n.t('tableHeaders.displayName'),
    alignRight: false,
  },
  { id: 'email', label: i18n.t('tableHeaders.email'), alignRight: false },
  { id: 'roles', label: i18n.t('tableHeaders.roles'), alignRight: false },
  { id: '' },
];

export const getUsersTableHead = () => [
  { id: 'name', label: i18n.t('tableHeaders.name'), alignRight: false },
  {
    id: 'displayName',
    label: i18n.t('tableHeaders.displayName'),
    alignRight: false,
  },
  { id: 'email', label: i18n.t('tableHeaders.email'), alignRight: false },
  {
    id: 'organisation',
    label: i18n.t('tableHeaders.organisation'),
    alignRight: false,
  },
  { id: 'score', label: i18n.t('tableHeaders.score'), alignRight: false },
  { id: '' },
];

export const getCompobotsTableHead = () => [
  { id: 'deviceId', label: i18n.t('tableHeaders.deviceId'), alignRight: false },
  {
    id: 'organisation',
    label: i18n.t('tableHeaders.organisation'),
    alignRight: false,
  },
  { id: 'score', label: i18n.t('tableHeaders.score'), alignRight: false },
  { id: '' },
];

export const getGroupsTableHead = () => [
  {
    id: 'displayName',
    label: i18n.t('tableHeaders.displayName'),
    alignRight: false,
  },
  { id: 'users', label: i18n.t('tableHeaders.users'), alignRight: false },
  { id: 'score', label: i18n.t('tableHeaders.score'), alignRight: false },
  { id: 'qrcode', label: i18n.t('tableHeaders.qrCode'), alignRight: false },
  { id: '' },
];

export const getAllGroupsTableHead = () => [
  {
    id: 'displayName',
    label: i18n.t('tableHeaders.displayName'),
    alignRight: false,
  },
  { id: 'users', label: i18n.t('tableHeaders.users'), alignRight: false },
  {
    id: 'organisation',
    label: i18n.t('tableHeaders.organisation'),
    alignRight: false,
  },
  { id: 'score', label: i18n.t('tableHeaders.score'), alignRight: false },
  { id: 'qrcode', label: i18n.t('tableHeaders.qrCode'), alignRight: false },
  { id: '' },
];

export const getQuizResultsTableHead = () => [
  { id: 'date', label: i18n.t('tableHeaders.date'), alignRight: false, },
  { id: 'users', label: i18n.t('tableHeaders.users'), alignRight: false },
  { id: 'score', label: i18n.t('tableHeaders.score'), alignRight: false },
  { id: 'organisation', label: i18n.t('tableHeaders.organisation'), alignRight: false, },
  { id: '' },
];

export const getNotificationsTableHead = () => [
  { id: 'title', label: i18n.t('tableHeaders.title'), alignRight: false },
  {
    id: 'description',
    label: i18n.t('tableHeaders.description'),
    alignRight: false,
  },
  { id: 'type', label: i18n.t('tableHeaders.type'), alignRight: false },
  {
    id: 'timestamp',
    label: i18n.t('tableHeaders.scheduledAt'),
    alignRight: false,
  },
  {
    id: 'delivered',
    label: i18n.t('tableHeaders.delivered'),
    alignRight: false,
  },
  {
    id: 'targetType',
    label: i18n.t('tableHeaders.targetType'),
    alignRight: false,
  },
  {
    id: 'numberOfTargets',
    label: i18n.t('tableHeaders.numberOfTargets'),
    alignRight: false,
  },
  { id: '' },
];

export const getChallengeTableHead = () => [
  {
    id: 'challengeName',
    label: i18n.t('tableHeaders.challengeName'),
    alignRight: false,
  },
  {
    id: 'organisation',
    label: i18n.t('tableHeaders.organisation'),
    alignRight: false,
  },
  {
    id: 'startDate',
    label: i18n.t('tableHeaders.startDate'),
    alignRight: false,
  },
  {
    id: 'endDate',
    label: i18n.t('tableHeaders.endDate'),
    alignRight: false,
  },
  {
    id: 'currenctScore',
    label: i18n.t('tableHeaders.currentScore'),
    alignRight: false,
  },
  {
    id: 'targetScore',
    label: i18n.t('tableHeaders.targetScore'),
    alignRight: false,
  },
  { id: '' },
];

export const getQuizTableHead = () => [
  {
    id: 'quizName',
    label: i18n.t('tableHeaders.quizName'),
    alignRight: false,
  },
  {
    id: 'challengeName',
    label: i18n.t('tableHeaders.challengeName'),
    alignRight: false,
  },
  {
    id: 'scoreForCorrect',
    label: i18n.t('tableHeaders.scoreForCorrect'),
    alignRight: false,
  },
  { id: '' },
];

export const getQuizFillsTableHead = () => [
  { id: 'timestamp', label: i18n.t('tableHeaders.timestamp'), alignRight: false, },
  { id: 'quizTitle_EN', label: i18n.t('tableHeaders.title'), alignRight: false, },

  { id: 'userName', label: i18n.t('tableHeaders.name'), alignRight: false },
  { id: 'userEmail', label: i18n.t('tableHeaders.email'), alignRight: false },

  { id: 'score', label: i18n.t('tableHeaders.score'), alignRight: false },
  { id: 'resultPercentage', label: i18n.t('tableHeaders.resultPercentage'), alignRight: false },

  { id: 'organisationName', label: i18n.t('tableHeaders.organisation'), alignRight: false },

  { id: '' },
];

export const getQuizStatisticsTableHead = () => [
  { id: 'quizName', label: i18n.t('tableHeaders.quizName'), alignRight: false, },
  { id: 'numberOfResults', label: i18n.t('tableHeaders.numberOfResults'), alignRight: false, },
  { id: 'minResults', label: i18n.t('tableHeaders.minResults'), alignRight: false, },
  { id: 'averageResults', label: i18n.t('tableHeaders.averageResults'), alignRight: false, },
  { id: 'maxResults', label: i18n.t('tableHeaders.maxResults'), alignRight: false, },
  { id: '' },
];

export const getImpactReportTableHead = () => [
  {
    id: 'organisation',
    label: i18n.t('tableHeaders.organisation'),
    alignRight: false,
  },
  { id: 'dateFrom', label: i18n.t('tableHeaders.dateFrom'), alignRight: false },
  { id: 'dateTo', label: i18n.t('tableHeaders.dateTo'), alignRight: false },
  {
    id: 'createdAt',
    label: i18n.t('tableHeaders.createdAt'),
    alignRight: false,
  },
  { id: '' },
];

export const getImpactReportTemplateTableHead = () => [
  { id: 'name', label: i18n.t('tableHeaders.name'), alignRight: false },
  {
    id: 'description',
    label: i18n.t('tableHeaders.description'),
    alignRight: false,
  },
  { id: 'language', label: i18n.t('tableHeaders.language'), alignRight: false },
  { id: '' },
];

export const getImpactReportModelTableHead = () => [
  { id: 'name', label: i18n.t('tableHeaders.name'), alignRight: false },
  {
    id: 'description',
    label: i18n.t('tableHeaders.description'),
    alignRight: false,
  },
  { id: '' },
];

export const getImpactReportMaintenanceDataTableHead = () => [
  {
    id: 'compobotNumber',
    label: i18n.t('tableHeaders.compobotNumber'),
    alignRight: false,
  },
  {
    id: 'measurementDate',
    label: i18n.t('tableHeaders.measurementDate'),
    alignRight: false,
  },
  {
    id: 'measurementWeight',
    label: i18n.t('tableHeaders.measurementWeight'),
    alignRight: false,
  },
  {
    id: 'organisation',
    label: i18n.t('tableHeaders.organisation'),
    alignRight: false,
  },
  { id: 'location', label: i18n.t('tableHeaders.location'), alignRight: false },
  { id: '' },
];

export const getImpactReportDistanceTableHead = () => [
  {
    id: 'distanceFrom',
    label: i18n.t('tableHeaders.distanceFrom'),
    alignRight: false,
  },
  {
    id: 'distanceTo',
    label: i18n.t('tableHeaders.distanceTo'),
    alignRight: false,
  },
  { id: 'distance', label: i18n.t('tableHeaders.distance'), alignRight: false },
  {
    id: 'typeOfTravel',
    label: i18n.t('tableHeaders.typeOfTravel'),
    alignRight: false,
  },
  { id: 'co2e', label: i18n.t('tableHeaders.co2e'), alignRight: false },
  { id: '' },
];

export const getImpactReportDistanceOverrideTableHead = () => [
  {
    id: 'distanceFrom',
    label: i18n.t('tableHeaders.distanceFrom'),
    alignRight: false,
  },
  {
    id: 'distanceTo',
    label: i18n.t('tableHeaders.distanceTo'),
    alignRight: false,
  },
  { id: 'distance', label: i18n.t('tableHeaders.distance'), alignRight: false },
  {
    id: 'typeOfTravel',
    label: i18n.t('tableHeaders.typeOfTravel'),
    alignRight: false,
  },
  { id: 'co2e', label: i18n.t('tableHeaders.co2e'), alignRight: false },
  {
    id: 'organisation',
    label: i18n.t('tableHeaders.organisation'),
    alignRight: false,
  },
  { id: '' },
];

export const getImpactReportSchedulingTableHead = () => [
  { id: 'name', label: i18n.t('tableHeaders.name'), alignRight: false },
  {
    id: 'description',
    label: i18n.t('tableHeaders.description'),
    alignRight: false,
  },

  {
    id: 'organisation',
    label: i18n.t('tableHeaders.organisation'),
    alignRight: false,
  },
  { id: 'template', label: i18n.t('tableHeaders.template'), alignRight: false },
  { id: 'model', label: i18n.t('tableHeaders.model'), alignRight: false },

  { id: '' },
];
