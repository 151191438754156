import { CreateImpactReportMaintenanceDataType, ImpactReportMaintenanceDataType, ModifyImpactReportMaintenanceDataType } from 'types/ImpactReportMaintenanceData.type';
import { ApiClient } from './interceptor';

const api = ApiClient.getInstance();

export const getAllImpactReportMaintenanceData = async () => {
  return await api.get('impact-report-maintenance-data');
};

export const getImpactReportMaintenanceDataById = async (id: string): Promise<ImpactReportMaintenanceDataType> => {
  return await api
    .get(`impact-report-maintenance-data/${id}`)
    .then((res) => res.data)
    .catch((err) => err);
};

export const deleteImpactReportMaintenanceData = async (ids: string) => {
  return await api.delete(`impact-report-maintenance-data/${ids}`);
};

export const deleteImpactReportMaintenanceDataBulk = async (ids: string[]) => {
  return await api.deleteWithParams('impact-report-maintenance-data', { UIds: ids });
};

export const createImpactReportMaintenanceData = async (impactReportMaintenanceData: CreateImpactReportMaintenanceDataType,) => {
  return await api.post('impact-report-maintenance-data', impactReportMaintenanceData);
};

export const modifyImpactReportMaintenanceData = async (id: string, impactReportMaintenanceData: ModifyImpactReportMaintenanceDataType,) => {
  return await api.put(`impact-report-maintenance-data/${id}`, impactReportMaintenanceData);
};

export const searchImpactReportMaintenanceData = async (searchTerm: string) => {
  return await api.get(`impact-report-maintenance-data/lookup/${searchTerm}`);
};


export const updateImpactReportMaintenanceDatabase = async () => {
  return await api.post('impact-report-maintenance-data/importDataFromGoogleSpreadsheet');
};