import { Button, Card, FormControl, Grid, InputLabel, MenuItem, Select, styled, TextField, Typography, } from '@mui/material';
import { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import SnackbarContext from 'contexts/snackbarContext';
import { createImpactReportScheduling, getImpactReportSchedulingById, modifyImpactReportScheduling } from 'services/impactReportScheduling.services';

import ErrorText from 'components/ErrorText/ErrorText';
import { addMonths, startOfDay, startOfMonth } from 'date-fns';
import DatePicker from 'react-date-picker';
import { theme } from 'theme';
import { ImpactReportModelType } from 'types/impactReportModel.type';
import { CreateImpactReportSchedulingType, ModifyImpactReportSchedulingType } from 'types/impactReportScheduling.type';
import { ImpactReportTemplateType } from 'types/impactReportTemplate.type';
import { OrganisationType } from 'types/organisations.type';

type CreateImpactReportSchedulingFormType = {
  name:string;
  description:string;
  
  organisation: string;
  template: string;
  model: string;

  firstDayOfExecution: Date;
  generationFrequency: string;

  emailtext : string;
  autosendemail: boolean;
};

const CustomForm = styled('form')`width: 100%;`;

interface CreateImpactReportSchedulingFormProps {
  impactReportId?: string;
  organisationList: OrganisationType[];
  impactReportTemplateList: ImpactReportTemplateType[];
  impactReportModelList: ImpactReportModelType[];
}

export default function CreateImpactReportSchedulingForm({impactReportId, organisationList, impactReportTemplateList, impactReportModelList} : CreateImpactReportSchedulingFormProps) {
  const { t } = useTranslation();
  const {handleSubmit, formState: { errors }, setValue, control, watch,} = useForm<CreateImpactReportSchedulingFormType>();
  const { handleOpen } = useContext(SnackbarContext);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      getImpactReportSchedulingById(id).then((data) => {

        setValue('name', data.name);
        setValue('description', data.description);

        setValue('generationFrequency', data.generationFrequency);
        setValue('firstDayOfExecution', data.firstDayOfExecution);

        setValue('emailtext', data.emailtext);
        setValue('autosendemail', data.autosendemail);

        setValue('organisation', data.organisation.id);
        setValue('template', data.template.id);
        setValue('model', data.model.id);
      });
    }
  }, [id]);

  const onSubmit = (data: CreateImpactReportSchedulingFormType) => {
    if (!id) {
      const impactReprotSchedulingObject: CreateImpactReportSchedulingType = {
        name: data.name,
        description: data.description,
        firstDayOfExecution: data.firstDayOfExecution,
        generationFrequency: data.generationFrequency,
        organisationId: data.organisation,
        templateId: data.template,
        modelId: data.model,
        emailtext: data.emailtext,
        autosendemail : data.autosendemail
      };
  
      createImpactReportScheduling(impactReprotSchedulingObject)
        .then((result) => {
          handleOpen(t('impactReportSchedulingForm.impactReportSchedulingCreatedSuccessfullyMessage'), 'success',);
          navigate('/impactReportSchedulings/'+result.data.id);
        })
        .catch(({ data }) => {
          handleOpen(data.message);
        });
    }
    else {

      const modifyImpactReportSchedulingObject: ModifyImpactReportSchedulingType = {
        id: id,
        name: data.name,
        description: data.description,
        autosendemail: data.autosendemail,
        emailtext: data.emailtext,
        firstDayOfExecution: data.firstDayOfExecution,
        generationFrequency: data.generationFrequency,
        modelId: data.model,
        organisationId: data.organisation,
        templateId: data.template,
      };

      modifyImpactReportScheduling(id, modifyImpactReportSchedulingObject)
        .then((result) => {
          handleOpen(t('impactReportSchedulingForm.impactReportSchedulingModifiedSuccessfullyMessage'), 'success',);

          getImpactReportSchedulingById(id).then((data) => {

            setValue('name', data.name);
            setValue('description', data.description);
    
            setValue('generationFrequency', data.generationFrequency);
            setValue('firstDayOfExecution', data.firstDayOfExecution);
    
            setValue('emailtext', data.emailtext);
            setValue('autosendemail', data.autosendemail);
    
            setValue('organisation', data.organisation.id);
            setValue('template', data.template.id);
            setValue('model', data.model.id);
          });
          
        })
        .catch(({ data }) => {
          handleOpen(data.message);
        });

    }
  };

  const checkKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  return (
    <Card sx={{ padding: '40px', maxWidth: '800px' }}>
      <CustomForm onSubmit={handleSubmit(onSubmit)} onKeyDown={checkKeyDown}>
        <Grid container spacing={2}>



          <Grid item xs={6}><Controller control={control} name="name" defaultValue="" render={({ field }) => ( <TextField {...field} fullWidth label={t('formFields.name')} type="text" inputProps={{ maxLength: 30 }} error={errors.name ? true : false} /> )} /> {errors.name && (<ErrorText>{t('formErrors.companyNameRequired')}</ErrorText>)}</Grid>



          
          <Grid item xs={6}><Controller control={control} defaultValue="" name="generationFrequency" render={({ field: { value, onChange } }) => (<TextField onChange={onChange} fullWidth variant="outlined" type="text" value={value} label={t('formFields.generationFrequency')} />)}/></Grid>
          <Grid item xs={9}><Controller control={control} defaultValue="" name="description" render={({ field: { value, onChange } }) => (<TextField onChange={onChange} fullWidth variant="outlined" type="text" value={value} label={t('formFields.description')} />)}/></Grid>

          <Grid item xs={3}>
            <Typography style={{ fontSize: 12, color: theme.palette.grey[600] }}>{t('formFields.firstDayOfExecution')}</Typography>
            <Controller control={control} name="firstDayOfExecution" defaultValue={startOfMonth( addMonths( startOfDay(new Date()),-1))}render={({ field: { value, onChange } }) => (<DatePicker value={  value ? new Date(value) : value} onChange={onChange} />)}/>
          </Grid>

          <Grid item xs={12}><Controller control={control} defaultValue = "" name="emailtext" render={({ field: { value, onChange } }) => (<TextField onChange={onChange} fullWidth variant="outlined" type="text" value={value} label={t('formFields.emailtext')} />)}/></Grid>
          <Grid item xs={12}><Controller control={control} defaultValue = {false} name="autosendemail" render={({ field: { value, onChange } }) => (<TextField onChange={onChange} fullWidth variant="outlined" type="text" value={value} label={t('formFields.autosendemail')} />)}/></Grid>

          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="label">{t('formFields.organisation')}</InputLabel>
              <Controller
                name="organisation"
                defaultValue = ""
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select value={value} onChange={onChange} label={t('formFields.organisation')} labelId="label" error={errors.organisation ? true : false}>
                    <MenuItem></MenuItem>
                    {organisationList.map((organisation: OrganisationType) => {return (<MenuItem key={organisation?.id}value={organisation?.id}>{organisation?.companyName}</MenuItem>);})}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="label">{t('formFields.template')}</InputLabel>
              <Controller
                name="template"
                defaultValue = ""
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select value={value} onChange={onChange} label={t('formFields.template')} labelId="label" error={errors.template ? true : false}>
                    <MenuItem></MenuItem>
                    {impactReportTemplateList.map((impactReportTemplate: ImpactReportTemplateType) => {return (<MenuItem key={impactReportTemplate?.id}value={impactReportTemplate?.id}>{impactReportTemplate?.name}</MenuItem>);})}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="label">{t('formFields.model')}</InputLabel>
              <Controller
                name="model"
                defaultValue = ""
                control={control}
                disabled={true}
                render={({ field: { onChange, value } }) => (
                  <Select value={value} onChange={onChange} label={t('formFields.model')} labelId="label" error={errors.model ? true : false}>
                    <MenuItem></MenuItem>
                    {impactReportModelList.map((impactReportModel: ImpactReportModelType) => {return (<MenuItem key={impactReportModel?.id}value={impactReportModel?.id}>{impactReportModel?.name}</MenuItem>);})}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>

        </Grid>

        <Button variant="contained" sx={{ marginTop: '20px' }} type="submit">{t('formFields.submitButtonLabel')}</Button>
      </CustomForm>
    </Card>
  );
}
