import { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Button,
  Box,
  Stack,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField, Typography,
} from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCompobotTelemetry, getCompobots, getCompobotKernelLogs, getCompobotById } from '../../services/compobot.service';
import { CompobotTelemetryType, CompobotType, CompobotKernelLogType } from '../../types/compobots.type';
import { lowerCase } from 'lodash';
import LineChart from '../../components/Chart/LineChart';
import BarChart from '../../components/Chart/BarChart';
//import TileNum from '../../components/Tile/TileNum';
import TileStr from '../../components/Tile/TileStr';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export default function CompoBotTelemetryPage() {// const [fvNev, fvSettere] = updateloReactFv<tipusa>(tipusAmitFelulirAKonkretTipusa)
  const { t } = useTranslation();
  const [telemetryData, setTelemetryData] = useState<CompobotTelemetryType[]>([]);
  const [logs, setLogs] = useState<CompobotKernelLogType[]>([]);
  const [compobots, setCompobots] = useState<CompobotType[]>([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [logLevel, setLogLevel] = useState('');
  const [lastUpdate, setLastUpdate] = useState('');
  const location = useLocation();
  const navigate = useNavigate();


  useEffect(() => {
    getCompobots().then((r) => setCompobots(r.data));
    const searchParams = new URLSearchParams(location.search);
    const deviceIdParam = searchParams.get('deviceId') || '';
    const fromDateParam = searchParams.get('fromDate') || '';
    const toDateParam = searchParams.get('toDate') || '';
    const logLevelParam = searchParams.get('logLevel') || '';
    const lastUpdateParam = searchParams.get('updatedAt') || 'unknown';
    setSelectedDeviceId(deviceIdParam);
    setFromDate(fromDateParam);
    setToDate(toDateParam);
    setLogLevel(logLevelParam);
    handleFetchTelemetry(deviceIdParam, fromDateParam, toDateParam);
    handleFetchLogs(deviceIdParam, fromDateParam, logLevelParam);
    setLastUpdate(lastUpdateParam);
    handleLastRefresh(deviceIdParam);
  }, [location.search]);

  const handleFetchTelemetry = async (deviceId?: string, fromDate?: string, toDate?: string) => {
    try {
      const fetchedTelemetry = await getCompobotTelemetry(deviceId, fromDate, toDate);
      setTelemetryData(fetchedTelemetry.data);
    } catch (error) {
      console.error('Error fetching telemetry: ', error);
    }
  };

  const handleFetchLogs = async (deviceId?: string, fromDate?: string, logLevel?: string) => {
    try {
      const fetchedLogs = await getCompobotKernelLogs(deviceId, fromDate, logLevel);
      setLogs(fetchedLogs.data);
    } catch (error) {
      console.error('Error fetching logs:', error);
    }
  };

  const handleLastRefresh = async (deviceId?: string) => {
    try {
      if(deviceId){
        const fetchedRefresh = await getCompobotById(deviceId);
        setLastUpdate(fetchedRefresh.data.updatedAt);
      }
    } catch (error) {
      console.error('Error fetching last refresh:', error);
    }
  };

  const updateSearchParams = (params: { [key: string]: string }) => {
    const searchParams = new URLSearchParams(location.search);
    Object.entries(params).forEach(([key, value]) => {
      value ? searchParams.set(key, value) : searchParams.delete(key);
    });
    navigate({ search: searchParams.toString() });
  };

  const clearFilters = () => {
    setSelectedDeviceId('');
    setFromDate('');
    setToDate('');
    setLogLevel('');
    updateSearchParams({ deviceId: '', fromDate: '', toDate: '', logLevel: '' });
    handleFetchTelemetry();
    handleFetchLogs();
  };

  //------tile and chart datas---------
  // TODO: status and lastLog getting dynamicly

  //status: if there was any kind of telemetry or kernel log in the last half an hour it should be online
  const stat = () => {
    const currentTime = new Date();

    const lastLog = new Date(logs.map(item => item.createdAt)[0]);
    const lastTelemetry = new Date(telemetryData.map(item => item.createdAt)[0]);
    let lastData = lastLog;
    if(lastLog.getDate() < lastTelemetry.getDate())
      lastData = lastTelemetry;

    console.info('The last log: ', lastLog.toDateString());
    console.info('The last telemetry: ', lastTelemetry.toDateString());
    const halfHourInMillis = 30 * 60 * 1000;
    const timeDifference = currentTime.getTime() - lastData.getTime();

    if(timeDifference > 0 && timeDifference <= halfHourInMillis){
      return 'Online';
    } else {
      return 'Offline';
    }
  };

  const fixing = (toFix : number, howPrec : number) => {
    if(!toFix)
      toFix = 0;
    return toFix.toFixed(howPrec);
  };

  const tileLogData = (type_ : string) => {
    return logs.filter(item => item.key === type_)
      .sort((one, other) => (one.createdAt > other.createdAt ? 1 : -1)).map(item => item.value);
  };

  const tileTelemetryData = (type_ : string) => {
    return telemetryData.filter(item => item.key === type_)
      .sort((one, other) => (one.createdAt > other.createdAt ? 1 : -1)).map(item => item.value);
  };

  const chartTelemetryData = (type_ : string) => { 
    return telemetryData.filter(item => lowerCase(item.key) === lowerCase(type_))
      .sort((one, other) => (one.createdAt > other.createdAt ? 1 : -1));
  };

  //---------UI return elements-----------

  return (
    <Container>
      <Stack direction="row" spacing={2} sx={{ mb: 4 }}>
        <Typography
          variant="h4"
          component="a"
          onClick={() => navigate('/compobots')}
          sx={{ cursor: 'pointer', textDecoration: 'underline' }}
        >
          {t('compobotTelemetryPage.compobotList')}
        </Typography>
        <Typography variant="h4" component="span">
          {'>'}
        </Typography>
        <Typography variant="h4" component="span">
          {t('compobotTelemetryPage.telemetry')}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel id="compobot-select-label">{t('compobotTelemetryPage.deviceId')}</InputLabel>
          <Select
            labelId="compobot-select-label"
            value={selectedDeviceId || 'all'}
            label={t('compobotTelemetryPage.deviceId')}
            onChange={(e) => {
              const value = e.target.value === 'all' ? '' : e.target.value;
              setSelectedDeviceId(value);
              updateSearchParams({ deviceId: value });
              handleFetchTelemetry(value, fromDate, toDate);
              handleFetchLogs(value, fromDate, logLevel);
              handleLastRefresh(value);
            }}
          >
            <MenuItem value="all">All</MenuItem>
            {compobots.map((compobot) => (
              <MenuItem key={compobot.deviceId} value={compobot.deviceId}>
                {compobot.organisation?.companyName} - {compobot.deviceId}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label={t('compobotTelemetryPage.fromDate')}
          type="date"
          value={fromDate}
          onChange={(e) => {
            setFromDate(e.target.value);
            updateSearchParams({ fromDate: e.target.value });
            handleFetchTelemetry(selectedDeviceId, e.target.value, toDate);
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label={t('To Date')}
          type="date"
          value={toDate}
          onChange={(e) => {
            setFromDate(e.target.value);
            updateSearchParams({ toDate: e.target.value });
            handleFetchTelemetry(selectedDeviceId, e.target.value, fromDate);
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button variant="contained" onClick={clearFilters}>
          {t('compobotTelemetryPage.clearFilters')}
        </Button>
      </Stack>
      
      <Paper sx={{ p: 6, mb: 1 }}>
        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', gap: 2, mb: 10 }}>
          <TileStr label={'Updated At'} value={lastUpdate} />
          <TileStr label={'Compobot Status'} value={stat().toString()} />
          <TileStr label={'Door opening counter'} value={fixing(tileTelemetryData('doorOpeningCount')[0], 0)} />
          <TileStr label={'CPU Temperature'} value={fixing(tileLogData('CPU temperature')[0], 3)} />
          <TileStr label={'CPU %'} value={fixing(tileLogData('CPU usage')[0], 3)} />
          <TileStr label={'Memory %'} value={fixing(tileLogData('Memory usage')[0], 3)} />
          
          <TileStr label={'Weight (current total)'} value={fixing(tileTelemetryData('weight')[0], 3)} />
          <TileStr label={'Weight (last delta)'} value={fixing(tileTelemetryData('deltaweight')[0], 3)} />
          <TileStr label={'Internet Ping (ms)'} value={fixing(tileLogData('Ping speed')[0], 3)} />
          <TileStr label={'Internet Upload Speed'} value={fixing(tileLogData('Internet upload speed')[0], 3)} />
          <TileStr label={'Internet Download Speed'} value={fixing(tileLogData('Internet download speed')[0], 3)} />
          <TileStr label={'Last Point'} value={fixing(tileTelemetryData('lastPoint')[0], 0)} />
        </Box>

        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', gap: 5, mb: 10 }}>          
          <LineChart Data={chartTelemetryData('temperature')} Title={'Temperature'} border_color={'rgb(255, 99, 132)'} background_color= {'rgba(255, 99, 132, 0.5)'} />
          <LineChart Data={chartTelemetryData('humidity')} Title={'Humidity'} border_color={'rgb(10, 255, 125)'} background_color= {'rgba(10, 255, 125, 0.5)'} />
          <LineChart Data={chartTelemetryData('weight')} Title={'Weight'} border_color={'rgb(132, 99, 255)'} background_color= {'rgba(132, 99, 255, 0.5)'} />
          <BarChart Data={chartTelemetryData('deltaweight')} Title={'Delta Weight'} border_color={'rgb(255, 85, 0)'} background_color= {'rgba(255, 85, 0, 0.5)'} />
        </Box>
      </Paper>
    </Container>
  );
}