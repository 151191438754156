import { Button, Card, Grid, styled, TextField } from '@mui/material';
import { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import SnackbarContext from 'contexts/snackbarContext';

import ErrorText from 'components/ErrorText/ErrorText';
import { createImpactReportTemplate, getImpactReportTemplateById, modifyImpactReportTemplate } from 'services/impactReportTemplate.services';
import { CreateImpactReportTemplateType, ImpactReportTemplateType } from 'types/impactReportTemplate.type';

type CreateImpactReportTemplateFormType = {
  name:string;
  description:string;
  documentpath: string;
  imagepath: string;
  language: string;
};

const CustomForm = styled('form')`width: 100%;`;

interface CreateImpactReportTemplateFormProps {
  impactReportTemplateId?: string;
}

export default function CreateImpactReportSchedulingForm({impactReportTemplateId:string} : CreateImpactReportTemplateFormProps) {
  const { t } = useTranslation();
  const {handleSubmit, formState: { errors }, setValue, control, watch,} = useForm<CreateImpactReportTemplateFormType>();
  const { handleOpen } = useContext(SnackbarContext);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      getImpactReportTemplateById(id).then((data) => {
        setValue('name', data.name);
        setValue('description', data.description);
        setValue('documentpath', data.documentpath);
        setValue('imagepath', data.imagepath);
        setValue('language', data.language);
      });
    }
  }, [id]);

  const onSubmit = (data: CreateImpactReportTemplateFormType) => {
    
    if (!id) {
      const createImpactReportTemplateObject: CreateImpactReportTemplateType = {
        name: data.name,
        description: data.description,
        documentpath: data.documentpath,
        imagepath: data.imagepath,
        language: data.language,
      };

      createImpactReportTemplate(createImpactReportTemplateObject)
        .then((result) => {
          console.log(result);
          handleOpen(t('impactReportTemplateForm.impactReportTemplateCreatedSuccessfullyMessage'), 'success',);

          navigate('/impactReportTemplates/'+result.data.id);
        })
        .catch(({ data }) => {
          handleOpen(data.message);
        });
    } else {

      const modifyImpactReportTemplateObject: ImpactReportTemplateType = {
        id: id,
        name: data.name,
        description: data.description,
        documentpath: data.documentpath,
        imagepath: data.imagepath,
        language: data.language,
      };

      modifyImpactReportTemplate(id, modifyImpactReportTemplateObject)
        .then((result) => {
          handleOpen(t('impactReportTemplateForm.impactReportTemplateModifiedSuccessfullyMessage'), 'success',);

          getImpactReportTemplateById(id).then((data) => {
            setValue('name', data.name);
            setValue('description', data.description);
            setValue('documentpath', data.documentpath);
            setValue('imagepath', data.imagepath);
            setValue('language', data.language);
          });
          
        })
        .catch(({ data }) => {
          handleOpen(data.message);
        });
    }
  };

  const checkKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  return (
    <Card sx={{ padding: '40px', maxWidth: '800px' }}>
      <CustomForm onSubmit={handleSubmit(onSubmit)} onKeyDown={checkKeyDown}>
        <Grid container spacing={2}>
          <Grid item xs={12}><Controller control={control} name="name" defaultValue="" render={({ field }) => ( <TextField {...field} fullWidth label={t('formFields.name')} type="text" inputProps={{ maxLength: 30 }} error={errors.name ? true : false} /> )} /> {errors.name && (<ErrorText>{t('formErrors.companyNameRequired')}</ErrorText>)}</Grid>          
          <Grid item xs={12}><Controller control={control} defaultValue="" name="description" render={({ field: { value, onChange } }) => (<TextField onChange={onChange} fullWidth variant="outlined" type="text" value={value} label={t('formFields.description')} />)}/></Grid>
          <Grid item xs={12}><Controller control={control} defaultValue="" name="imagepath" render={({ field: { value, onChange } }) => (<TextField onChange={onChange} fullWidth variant="outlined" type="text" value={value} label={t('formFields.imagePath')} />)}/></Grid>
          <Grid item xs={12}><Controller control={control} defaultValue="" name="documentpath" render={({ field: { value, onChange } }) => (<TextField onChange={onChange} fullWidth variant="outlined" type="text" value={value} label={t('formFields.documentPath')} />)}/></Grid>
          <Grid item xs={12}><Controller control={control} defaultValue="" name="language" render={({ field: { value, onChange } }) => (<TextField onChange={onChange} fullWidth variant="outlined" type="text" value={value} label={t('formFields.language')} />)}/></Grid>
        </Grid>

        <Button variant="contained" sx={{ marginTop: '20px' }} type="submit">{t('formFields.submitButtonLabel')}</Button>
      </CustomForm>
    </Card>
  );
}
