/* eslint-disable indent */
import { Box, Container, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PageHeader from 'components/PageHeader/PageHeader';
import DateTimePicker from 'react-datetime-picker';
import { getQuizById } from 'services/quiz.service';
import { theme } from 'theme';
import { QuizType } from 'types/quizes.type';

export default function ViewQuizPage() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [quiz, setQuiz] = useState<QuizType | null>(null);

  useEffect(() => {
    if (id) {
      getQuizById(id).then(({ data }: { data: QuizType }) => {
        setQuiz(data);
      });
    }
  }, [id]);

  return (
    <Container maxWidth="xl">
      <PageHeader
        title={t('formFields.quizView')}
        hasButton={false}
        hasBackButton={true}
      />
      <form>
        <Grid container spacing={2}>
          {/* Title fields */}
          <Grid item xs={6}>
            <TextField
              fullWidth
              label={t('formFields.title_eng')}
              variant="outlined"
              value={quiz ? quiz.title_EN : ''}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label={t('formFields.title_hun')}
              variant="outlined"
              value={quiz ? quiz.title_HU : ''}
              disabled
            />
          </Grid>

          {/* Score for correct field */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t('formFields.scoreForCorrect')}
              variant="outlined"
              value={quiz ? quiz.scoreForCorrect : ''}
              disabled
              type="number"
            />
          </Grid>

          <Grid item xs={6}>
            <Typography
              style={{ fontSize: 12, color: theme.palette.grey[600] }}
            >
              {t('formFields.startDate')}
            </Typography>
            <DateTimePicker
              value={quiz ? quiz.startDate : null}
              minDetail="year"
              disableClock
              disabled={true}
              clearIcon={null}
              format="dd/MM/yyyy HH:mm"
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              style={{ fontSize: 12, color: theme.palette.grey[600] }}
            >
              {t('formFields.endDate')}
            </Typography>
            <DateTimePicker
              value={quiz ? quiz.endDate : null}
              minDetail="year"
              disableClock
              disabled={true}
              clearIcon={null}
              format="dd/MM/yyyy HH:mm"
            />
          </Grid>

          {/* Challenge select field */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t('formFields.selectChallenge')}
              variant="outlined"
              value={
                quiz && quiz.challenges?.length > 0
                  ? quiz.challenges
                      .map((challenge) => challenge.name_EN)
                      .join(', ')
                  : t('quizesPage.notAssignedChallenge')
              }
              disabled
            />
          </Grid>

          {/* Questions fields */}
          {quiz?.questions.map((question, questionIndex) => (
            <React.Fragment key={questionIndex}>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  label={t('formFields.question_eng')}
                  variant="outlined"
                  value={question.text_EN}
                  disabled
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  label={t('formFields.question_hun')}
                  variant="outlined"
                  value={question.text_HU}
                  disabled
                />
              </Grid>
              {/* Answers fields */}
              {question.answers.map((answer, answerIndex) => (
                <React.Fragment key={answerIndex}>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label={t('formFields.answers_eng')}
                      variant="outlined"
                      value={answer.text_EN}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label={t('formFields.answers_hun')}
                      variant="outlined"
                      value={answer.text_HU}
                      disabled
                    />
                  </Grid>
                  {/* Is Correct field */}
                  <Grid item xs={1}>
                    <TextField
                      fullWidth
                      label={t('formFields.isCorrect')}
                      variant="outlined"
                      value={
                        answer.isCorrect
                          ? t('formFields.isCorrectYes')
                          : t('formFields.isCorrectNo')
                      }
                      disabled
                    />
                  </Grid>
                </React.Fragment>
              ))}
              {question.photoUrl && (
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    {t('formFields.photoUrl')}
                  </Typography>
                  <Box
                    component="img"
                    sx={{
                      height: 233,
                      width: 350,
                      maxHeight: { xs: 233, md: 167 },
                      maxWidth: { xs: 350, md: 250 },
                    }}
                    alt={t('formFields.questionPhoto')}
                    src={question.photoUrl}
                  />
                </Grid>
              )}
            </React.Fragment>
          ))}
        </Grid>
      </form>
    </Container>
  );
}
