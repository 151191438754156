import {
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import PageHeader from 'components/PageHeader/PageHeader';
import Popup from 'components/Popup/Popup';
import MoreMenu from 'components/table/MoreMenu';
import SearchNotFound from 'components/table/SearchNoFound';
import TableHeader from 'components/table/TableHeader';
import TableToolBar from 'components/table/TableToolbar';
import { getQuizResultsTableHead } from 'constans/table-headers';
import SnackbarContext from 'contexts/snackbarContext';
import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { deleteGroup, deleteGroupBulk } from 'services/group.service';
import { sortByColumn } from 'services/list.service';
import { getQuizById } from 'services/quiz.service';
import { getQuizLogsByQuizId } from 'services/quiz_log.service';
import { QuizLogType, QuizType } from 'types/quizes.type';

export default function ViewQuizResults() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const languageTitle = language === 'hu' ? 'title_HU' : 'title_EN';
  const { id: quizId } = useParams<{ id: string }>();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [selected, setSelected] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { handleOpen } = useContext(SnackbarContext);
  const [quizLogs, setQuizLogs] = useState<QuizLogType[]>([]);
  const [quizData, setQuizData] = useState<QuizType>();
  const [openModal, setOpenModal] = useState(false);

  const fetchQuizLogs = async () => {
    if (quizId) {
      try {
        const response = await getQuizLogsByQuizId({
          quizId,
        });
        const quizResponse = await getQuizById(quizId);
        setQuizData(quizResponse.data);

        return response.data;
      } catch (error) {
        console.error('Error fetching groups:', error);
        throw new Error('Error fetching groups');
      }
    }
  };

  const filterQuizLogs = (logs: QuizLogType[], filterName: string) => {
    return logs.filter(
      (log) =>
        (log.user.firstname + ' ' + log.user.lastname)
          .toLowerCase()
          .includes(filterName.toLowerCase()) ||
        (t('groupsPage.private')
          .toLocaleLowerCase()
          .includes(filterName.toLocaleLowerCase()) &&
          !log.user.organisation) ||
        (log.user.organisation &&
          log.user.organisation[0].companyName
            .toLowerCase()
            .includes(filterName.toLowerCase())),
    );
  };

  const handleSearch = async (filterName: string) => {
    try {
      let logs: QuizLogType[] = await fetchQuizLogs();
      if (filterName) {
        logs = filterQuizLogs(logs, filterName);
      }
      setQuizLogs(logs);
    } catch (error) {
      console.error('Error handling search:', error);
      throw new Error('Error handling search');
    }
  };

  useEffect(() => {
    fetchQuizLogs()
      .then((logs) => {
        setQuizLogs(logs);
      })
      .catch((error) => {
        console.error('Error fetching groups:', error);
      });
  }, []);

  const searchFilter = useCallback(
    debounce((filterName) => {
      handleSearch(filterName);
    }, 300),
    [],
  );

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked: boolean) => {
    if (checked) {
      setSelected(quizLogs.map((n: QuizLogType) => n.id));
      return;
    }

    setSelected([]);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteGroup = (groupId: string) => {
    deleteGroup(groupId)
      .then(() => {
        setQuizLogs(quizLogs.filter(({ id }) => id !== groupId));
        handleSelectAllClick(false);
        setOpenModal(false);
        handleOpen(t('groupsPage.groupDeletedSuccessMessage'), 'success');
      })
      .catch(({ data }) => {
        handleOpen(data.message);
      });
  };

  const handleDeleteGroupBulk = (ids: string[]) => {
    deleteGroupBulk(ids)
      .then(() => {
        setQuizLogs(quizLogs.filter(({ id }) => !ids.includes(id)));
        setOpenModal(false);
        handleSelectAllClick(false);
        handleOpen(t('groupsPage.groupsDeletedSuccessMessage'), 'success');
      })
      .catch(({ data }) => {
        handleOpen(data.message);
      });
  };

  const deleteGroupAction = () => {
    if (selected.length > 1) {
      handleDeleteGroupBulk(selected);
    } else {
      handleDeleteGroup(selected[0]);
    }
  };

  const openModalHandler = (id?: string) => {
    setOpenModal(true);

    if (id) {
      setSelected([id]);
    }
  };

  const sortedQuizLogs = sortByColumn(
    quizLogs,
    orderBy as keyof QuizLogType,
    order,
  );

  const quizResultsAllTableHead = useMemo(getQuizResultsTableHead, [language]);

  return (
    <Container>
      <PageHeader
        title={`${t('resultsPage.title')} - ${quizData?.[languageTitle]}`}
        hasButton={false}
        hasBackButton={true}
      />
      <Card>
        <TableToolBar
          numSelected={selected.length}
          onFilterName={searchFilter}
          deleteHandler={() => openModalHandler()}
        />

        <TableContainer>
          <Table>
            <TableHeader
              order={order}
              orderBy={orderBy}
              disableSelect={true}
              headLabel={quizResultsAllTableHead}
              rowCount={quizLogs.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {sortedQuizLogs
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const {
                    id,
                    user: { firstname, lastname, organisation, id: userId },
                    score,
                    timestamp,
                  } = row;

                  return (
                    <TableRow hover key={id} tabIndex={-1} role="checkbox">
                      <TableCell
                        align="left"
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {new Date(timestamp)
                          .toLocaleDateString('hu-HU')
                          .replaceAll(' ', '') +
                          ' ' +
                          new Date(timestamp)
                            .toLocaleTimeString('hu-HU')
                            .replaceAll(' ', '')}
                      </TableCell>
                      <TableCell align="left">
                        {firstname} {lastname}
                      </TableCell>
                      <TableCell align="left">{score}</TableCell>
                      <TableCell align="left">
                        {organisation.map((org) => org.companyName + ', ')}
                      </TableCell>
                      <TableCell align="right">
                        <MoreMenu
                          isNotification
                          isNotificationDelivered
                          id={`user/${userId}`}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            {!quizLogs.length && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={quizLogs.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, page) => setPage(page)}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('pagination.rowsPerPage')}
        />
      </Card>
      <Popup
        type="delete"
        open={openModal}
        onConfirm={deleteGroupAction}
        handleClose={() => setOpenModal(false)}
      />
    </Container>
  );
}
