import {Card,Checkbox,Container,Table,TableBody,TableCell,TableContainer,TablePagination,TableRow,} from '@mui/material';
import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PageHeader from 'components/PageHeader/PageHeader';
import Popup from 'components/Popup/Popup';
import MoreMenu from 'components/table/MoreMenu';
import SearchNotFound from 'components/table/SearchNoFound';
import TableHeader from 'components/table/TableHeader';
import TableToolBar from 'components/table/TableToolbar';
import { getImpactReportTemplateTableHead } from 'constans/table-headers';
import SnackbarContext from 'contexts/snackbarContext';
import {handleClick,handleSelectAllClick,sortByColumn,} from 'services/list.service';

import {getImpactReportTemplates, searchImpactReportTemplates, deleteImpactReportTemplate, deleteImpactReportTemplateBulk} from 'services/impactReportTemplate.services';
import { ImpactReportTemplateType } from 'types/impactReportTemplate.type';

export default function impactReportTemplatePage() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [selected, setSelected] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { handleOpen } = useContext(SnackbarContext);
  const [impactReportList, setImpactReportTemplateList] = useState<ImpactReportTemplateType[]>([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    getImpactReportTemplateHandler();
  }, []);

  const getImpactReportTemplateHandler = () => {
    getImpactReportTemplates().then((response) => {
      setImpactReportTemplateList(response.data);
    });
  };

  const searchFilter = useCallback(
    debounce((filterName) => {
      if (filterName) {
        searchImpactReportTemplates(filterName).then((response) => {
          setImpactReportTemplateList(response.data);
        });
      } else {
        getImpactReportTemplateHandler();
      }
    }, 300),
    [],
  );

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteImpactReport = (impactReportId: string) => {
    deleteImpactReportTemplate(impactReportId)
      .then(() => {
        setImpactReportTemplateList([...impactReportList].filter(({ id }) => id !== impactReportId));
        handleSelectAllClick(false, impactReportList);
        setOpenModal(false);
        handleOpen(t('impactReportTemplatePage.impactReportTemplateDeletedSuccessMessage'), 'success');
      })
      .catch(({ data }) => {
        handleOpen(data.message);
      });
  };

  const handleDeleteImpactReportBulk = (impactReportIds: string[]) => {
    deleteImpactReportTemplateBulk(impactReportIds)
      .then(() => {
        setImpactReportTemplateList(impactReportList.filter(({ id }) => !impactReportIds.includes(id)));
        handleSelectAllClick(false, impactReportList);
        setOpenModal(false);
        handleOpen(t('impactReportTemplatePage.impactReportTemplatesDeletedSuccessMessage'), 'success');
      })
      .catch(({ data }) => {
        handleOpen(data.message);
      });
  };

  const deleteImpactReportAction = () => {
    if (selected.length > 1) {
      handleDeleteImpactReportBulk(selected);
    } else {
      handleDeleteImpactReport(selected[0]);
    }
  };

  const openModalHandler = (id?: string) => {
    setOpenModal(true);
    if (id) {
      setSelected([id]);
    }
  };

  const sortedImpactReportList = sortByColumn(
    impactReportList,
    orderBy as keyof ImpactReportTemplateType,
    order,
  );

  const impactReportTableHead = useMemo(getImpactReportTemplateTableHead, [language]);

  return (
    <Container>
      <PageHeader title={t('impactReportTemplatePage.title')} buttonTitle={t('impactReportTemplatePage.buttonTitle').toString()} hasButton hasBackButton />
      <Card>
        <TableToolBar
          numSelected={selected.length}
          onFilterName={searchFilter}
          deleteHandler={() => openModalHandler()}
        />

        <TableContainer>
          <Table>
            {
              <TableHeader
                order={order}
                orderBy={orderBy}
                headLabel={impactReportTableHead}
                rowCount={impactReportList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={(checked) =>
                  setSelected(handleSelectAllClick(checked, impactReportList))
                }
              />
            }
            <TableBody>
              {sortedImpactReportList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const {
                    id,
                    name,
                    description,
                    language
                  } = row;
                  const isItemSelected = selected.indexOf(id) !== -1;

                  return (
                    <TableRow
                      hover
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >

                      <TableCell padding="checkbox"><Checkbox checked={isItemSelected}onClick={() => setSelected(handleClick(id, selected))}/></TableCell>
                      <TableCell align="left">{name}</TableCell>
                      <TableCell align="left">{description }</TableCell>
                      <TableCell align="left">{language }</TableCell>

                      <TableCell align="right">
                        <MoreMenu
                          onDelete={() => openModalHandler(id)}
                          id={id}
                        />
                      </TableCell>

                    </TableRow>
                  );
                })}
            </TableBody>
            {!impactReportList.length && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={impactReportList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, page) => setPage(page)}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('pagination.rowsPerPage')}
        />
      </Card>
      <Popup
        type="delete"
        open={openModal}
        onConfirm={deleteImpactReportAction}
        handleClose={() => setOpenModal(false)}
      />
    </Container>
  );
}
