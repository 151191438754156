import { CreateImpactReportTemplateType, ImpactReportTemplateType } from 'types/impactReportTemplate.type';
import { ApiClient } from './interceptor';

const api = ApiClient.getInstance();

export const getImpactReportTemplates = async () => {
  return await api.get('impact-report-template');
};

export const getImpactReportTemplateById = async (id: string): Promise<ImpactReportTemplateType> => {
  return await api
    .get(`impact-report-template/${id}`)
    .then((res) => res.data)
    .catch((err) => err);
};

export const deleteImpactReportTemplate = async (ids: string) => {
  return await api.delete(`impact-report-template/${ids}`);
};

export const deleteImpactReportTemplateBulk = async (ids: string[]) => {
  return await api.deleteWithParams('impact-report-template', { UIds: ids });
};

export const createImpactReportTemplate = async (impactReportTemplate: CreateImpactReportTemplateType,) => {
  return await api.post('impact-report-template', impactReportTemplate);
};

export const modifyImpactReportTemplate = async (id: string, impactReportTemplate: ImpactReportTemplateType,) => {
  return await api.put(`impact-report-template/${id}`, impactReportTemplate);
};


export const searchImpactReportTemplates = async (searchTerm: string) => {
  return await api.get(`impact-report-template/lookup/${searchTerm}`);
};