import { createQuizToFormData, CreateQuizType } from '../types/quizes.type';
import { ApiClient } from './interceptor';

const api = ApiClient.getInstance();

export const createQuiz = async (quiz: CreateQuizType) => {
  const formData = createQuizToFormData(quiz);
  return await api.post('quiz', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const modifyQuiz = async (id: string, quiz: CreateQuizType) => {
  const formData = createQuizToFormData(quiz);
  return await api.put(`quiz/${id}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const getQuizes = async () => {
  return await api.get('quiz');
};

export const getQuizStatistics = async (startDate?: Date, endDate?: Date) => {
  const params = new URLSearchParams();
  if (startDate) params.append('startDate', startDate.toISOString());
  if (endDate) params.append('endDate', endDate.toISOString());
  return await api.get('quiz/statistics',params);
};
export const getQuizQuestionStatistics = async (id: string) => {
  return await api.get(`quiz/${id}/statistics`);
};

export const getQuizById = async (id: string) => {
  return await api.get(`quiz/${id}`);
};

export const deleteQuiz = async (id: string) => {
  return await api.delete(`quiz/${id}`);
};

export const assignQuiz = async (
  quizId: string,
  challengeIds: { challengeIds: string[] },
) => {
  return await api.post(`quiz/${quizId}/assign/challenges`, challengeIds);
};

export const removeQuiz = async (quizId: string, challengeId: string) => {
  return await api.delete(`quiz/${quizId}/remove/${challengeId}`);
};

export const removeAllChallenges = async (quizId: string) => {
  return await api.delete(`quiz/remove/all-challenges/${quizId}`);
};
