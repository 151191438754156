import {
  Button,
  Card,
  Checkbox,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PageHeader from 'components/PageHeader/PageHeader';
import PinPopup from 'components/PinPopup/PinPopup';
import Popup from 'components/Popup/Popup';
import MoreMenu from 'components/table/MoreMenu';
import SearchNotFound from 'components/table/SearchNoFound';
import TableHeader from 'components/table/TableHeader';
import TableToolbar from 'components/table/TableToolbar';
import { getOrganisationTableHead } from 'constans/table-headers';
import SnackbarContext from 'contexts/snackbarContext';
import {
  handleClick,
  handleSelectAllClick,
  sortByColumn,
} from 'services/list.service';
import {
  deleteOrganisation,
  deleteOrganisationBulk,
  getOrganizations,
  searchOrganisations,
} from 'services/organisation.service';
import { OrganisationType } from 'types/organisations.type';
import { Link as RouterLink } from 'react-router-dom';

export default function OrganisationsPage() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [selected, setSelected] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { handleOpen } = useContext(SnackbarContext);
  const [organisationList, setOrganisationList] = useState<OrganisationType[]>(
    [],
  );
  const [openModal, setOpenModal] = useState(false);
  const [pinCode, setPinCode] = useState<number | null>(null);
  const [openPinModal, setOpenPinModal] = useState(false);

  useEffect(() => {
    getOrganizationsHandler();
  }, []);

  const getOrganizationsHandler = () => {
    getOrganizations().then((response) => {
      setOrganisationList(response.data);
    });
  };

  const searchFilter = useCallback(
    debounce((filterName) => {
      if (filterName) {
        searchOrganisations(filterName).then((response) => {
          setOrganisationList(response.data);
        });
      } else {
        getOrganizationsHandler();
      }
    }, 300),
    [],
  );

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteOrganization = (orgId: string) => {
    deleteOrganisation(orgId)
      .then(() => {
        const newList = organisationList.filter(({ id }) => id !== orgId);
        setOrganisationList(newList);
        handleSelectAllClick(false, organisationList);
        setOpenModal(false);
        handleOpen(t('organisationsPage.orgDeletedSuccessMessage'), 'success');
      })
      .catch(({ data }) => {
        handleOpen(data.message);
      });
  };

  const handleDeleteOrganizationBulk = (orgIds: string[]) => {
    deleteOrganisationBulk(orgIds)
      .then(() => {
        const newList = organisationList.filter(
          ({ id }) => !orgIds.includes(id),
        );
        setOrganisationList(newList);
        handleSelectAllClick(false, organisationList);
        setOpenModal(false);
        handleOpen(t('organisationsPage.orgsDeletedSuccessMessage'), 'success');
      })
      .catch(({ data }) => {
        handleOpen(data.message);
      });
  };

  const deleteOrgAction = () => {
    if (selected.length > 1) {
      handleDeleteOrganizationBulk(selected);
    } else {
      handleDeleteOrganization(selected[0]);
    }
  };

  const openModalHandler = (id?: string) => {
    setOpenModal(true);
    if (id) {
      setSelected([id]);
    }
  };

  const openPinModalHandler = (id: string, pin: number) => {
    setPinCode(pin);
    setSelected([id]);
    setOpenPinModal(true);
  };

  const changePinHandler = () => {
    setOpenPinModal(false);
    getOrganizationsHandler();
  };

  const sortedOrgs = sortByColumn(
    organisationList,
    orderBy as keyof OrganisationType,
    order,
  );

  const orgTableHead = useMemo(getOrganisationTableHead, [language]);

  return (
    <Container>
      <PageHeader title={t('organisationsPage.title')} buttonTitle={t('organisationsPage.buttonTitle').toString()} hasButton />

      <Button variant="outlined" sx={{ margin: '0px 5px 20px 5px' }} component={RouterLink} to={'/organisations/statistics'}>         {t('organisationsPage.statisticsButtonTitle')}</Button>

      <Card>
        <TableToolbar
          numSelected={selected.length}
          onFilterName={searchFilter}
          deleteHandler={() => openModalHandler()}
        />

        <TableContainer>
          <Table>
            {
              <TableHeader
                order={order}
                orderBy={orderBy}
                headLabel={orgTableHead}
                rowCount={organisationList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={(checked) =>
                  setSelected(handleSelectAllClick(checked, organisationList))
                }
              />
            }
            <TableBody>
              {sortedOrgs
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const {
                    id,
                    companyName,
                    contactEmail,
                    emailHandler,
                    address,
                    users,
                    __groups__,
                    PIN,
                    isOpen,
                  } = row;
                  const isItemSelected = selected.indexOf(id) !== -1;

                  return (
                    <TableRow
                      hover
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onClick={() => setSelected(handleClick(id, selected))}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography
                            variant="subtitle2"
                            noWrap
                            sx={{ fontWeight: 600 }}
                          >
                            {companyName}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="left">{contactEmail}</TableCell>
                      <TableCell align="left">{emailHandler}</TableCell>
                      <TableCell align="left">{users?.length}</TableCell>
                      <TableCell align="left">{__groups__?.length}</TableCell>
                      <TableCell align="left">{address}</TableCell>
                      <TableCell align="left">
                        <Checkbox checked={isOpen} disabled />
                      </TableCell>

                      <TableCell align="right">
                        <MoreMenu
                          onDelete={() => openModalHandler(id)}
                          onChangePin={() => openPinModalHandler(id, PIN)}
                          id={id}
                          groups
                          hasPin
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            {!organisationList.length && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={organisationList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, page) => setPage(page)}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('pagination.rowsPerPage')}
        />
      </Card>
      <Popup
        type="delete"
        open={openModal}
        onConfirm={deleteOrgAction}
        handleClose={() => setOpenModal(false)}
      />
      <PinPopup
        open={openPinModal}
        handleClose={changePinHandler}
        id={selected[0]}
        pinCode={pinCode}
      />
    </Container>
  );
}
