import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ChallengeType } from 'types/challenges.type';

export type SelectChallengeProps = {
  control: Control<any>;
  challengeList: ChallengeType[];
  errors: any;
};

const SelectChallenge: React.FC<SelectChallengeProps> = ({
  control,
  challengeList,
  errors,
}) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel htmlFor="label">{t('formFields.selectChallenge')}</InputLabel>
      <Controller
        name="challengeIds"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            value={Array.isArray(value) ? value : []}
            onChange={(event: SelectChangeEvent<string[]>) => {
              const selectedValues = event.target.value as string[];

              if (selectedValues.includes('')) {
                onChange([]);
              } else {
                onChange(selectedValues);
              }
            }}
            label={t('formFields.selectChallenge')}
            renderValue={(selected) => {
              if (Array.isArray(selected) && selected.length === 0) {
                return t('quizesPage.unAssigned');
              }
              return selected
                .map(
                  (selectedChallengeId) =>
                    challengeList.find(
                      (challenge) => challenge.id === selectedChallengeId,
                    )?.name_EN,
                )
                .join(', ');
            }}
            labelId="label"
            error={!!errors.challenges}
            multiple
          >
            <MenuItem value="">{t('quizesPage.unAssigned')}</MenuItem>
            {challengeList.length > 0 &&
              challengeList.map((challenge: ChallengeType) => (
                <MenuItem
                  sx={{
                    color: 'grey.900',
                    '&.Mui-selected': {
                      backgroundColor: 'rgb(0, 167, 124, 0.7)',
                      color: 'white',
                    },
                    '&.Mui-selected:hover': {
                      backgroundColor: 'rgb(0, 167, 124, 0.7)',
                    },
                  }}
                  key={challenge.id}
                  value={challenge.id}
                >
                  {challenge.name_EN}
                </MenuItem>
              ))}
          </Select>
        )}
      />
    </FormControl>
  );
};

export default SelectChallenge;
