import { Button,Card,Grid,styled,TextField, Typography,} from '@mui/material';
import { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import ErrorText from 'components/ErrorText/ErrorText';
import SnackbarContext from 'contexts/snackbarContext';
import { getImpactReportById} from 'services/impactReport.services';

import { OrganisationType } from 'types/organisations.type';
import { ImpactReportTemplateType } from 'types/impactReportTemplate.type';
import { ImpactReportModelType } from 'types/impactReportModel.type';
import { theme } from 'theme';
import DatePicker from 'react-date-picker';

import { OrganisationBaseType } from 'types/organisations.type';
/*
import { ImpactReportTemplateType } from 'types/impactReportTemplate.type';
import { ImpactReportModelType } from 'types/impactReportModel.type';
*/

type EditImpactReportFormType = {
  organisation: OrganisationBaseType;
  template: ImpactReportTemplateType;
  model: ImpactReportModelType;
  createdAt: Date;
  dateFrom: Date;
  dateTo: Date;
  documentPathDocx: string;
  documentPathPDF: string;
};

const CustomForm = styled('form')`width: 100%;`;

interface EditImpactReportFormProps {
  impactReportId?: string;
  organisationList: OrganisationType[];
  impactReportTemplateList: ImpactReportTemplateType[];
  impactReportModelList: ImpactReportModelType[];
}

export default function EditImpactReportForm({impactReportId} : EditImpactReportFormProps) {
  const { t } = useTranslation();
  const {
    handleSubmit, 
    formState: { errors }, 
    setValue, 
    control, 
    watch,
  } = useForm<EditImpactReportFormType>();
  const { handleOpen } = useContext(SnackbarContext);
  
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {

    if (id) {
      getImpactReportById(id).then((data) => {
        setValue('organisation', data.organisation);
        setValue('template', data.template);
        
        setValue('model', data.model);

        setValue('createdAt', new Date(data.createdAt));
        setValue('dateFrom', new Date(data.dateFrom));
        setValue('dateTo', new Date(data.dateTo));
        setValue('documentPathDocx', process.env.REACT_APP_API_BASE_URL +'../'+data.documentPathDocx);
        setValue('documentPathPDF', process.env.REACT_APP_API_BASE_URL +'../'+data.documentPathPDF);
      });
    }
  }, [id]);


  const checkKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  return (
    <Card sx={{ padding: '40px', maxWidth: '800px' }}>
      <CustomForm onKeyDown={checkKeyDown}>
        <Grid container spacing={2}>


          {/* Date fields */}
          <Grid item xs={4}>
            <Typography style={{ fontSize: 12, color: theme.palette.grey[600] }}>{t('formFields.dateFrom')}</Typography>
            <Controller
              control={control}
              name="dateFrom"
              defaultValue={new Date()}
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  value={value}
                  disabled = {true}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography style={{ fontSize: 12, color: theme.palette.grey[600] }}>{t('formFields.dateTo')}</Typography>
            <Controller
              control={control}
              name="dateTo"
              defaultValue={new Date()}
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  value={value}
                  disabled = {true}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography style={{ fontSize: 12, color: theme.palette.grey[600] }}>{t('formFields.createdAt')}</Typography>
            <Controller
              control={control}
              name="createdAt"
              defaultValue={new Date()}
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  value={value}
                  disabled = {true}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          
          <Grid item xs={12}>
            <Controller control={control} name="organisation" render={({ field: { value, onChange } }) => (<TextField disabled={true}  onChange={onChange} fullWidth variant="outlined" type="text" value={value?.companyName || ''} label={t('formFields.organisationDetails')} />)}/>
          </Grid>

          <Grid item xs={12}>
            <Controller control={control} name="template" render={({ field: { value, onChange } }) => (<TextField disabled={true}  onChange={onChange} fullWidth variant="outlined" type="text" value={value?.name || ''} label={t('formFields.templateDetails')} />)}/>
          </Grid>


          <Grid item xs={12}>
            <Controller control={control} name="model" render={({ field: { value, onChange } }) => (<TextField disabled={true}  onChange={onChange} fullWidth variant="outlined" type="text" value={value?.name || ''} label={t('formFields.modelDetails')} />)}/>
          </Grid>

        </Grid>

        <Controller
          name="documentPathDocx"
          control={control}
          render={({ field: { value } }) => (
            <a href={value}>
              <Button variant="contained" sx={{ marginTop: '20px', marginRight: '20px' }}>{t('formFields.downloadButtonDocxLabel')}</Button>
            </a>
          )}
        />

        <Controller
          name="documentPathPDF"
          control={control}

          render={({ field: { value } }) => (
            <a href={value}>
              <Button variant="contained" sx={{ marginTop: '20px' }}>{t('formFields.downloadButtonPDFLabel')}</Button>
            </a>
          )}
        />



      </CustomForm>
    </Card>
  );
}
