import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import CreateImpactReportDistanceOverrideForm from 'components/CreateImpactReportDistanceOverrideForm/CreateImpactReportDistanceOverrideForm';
import PageHeader from 'components/PageHeader/PageHeader';
import { useEffect, useState } from 'react';
import { getImpactReportModels } from 'services/impactReportModel.services';
import { getImpactReportTemplates } from 'services/impactReportTemplate.services';
import { getOrganizations } from 'services/organisation.service';

export default function CreateImpactReportDistanceOverridePage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const impactReportDistanceOverrideId = id || '';

  const [organisationList, setOrganisationList] = useState([]);
  useEffect(() => {
    getOrganizations().then((response) => {
      setOrganisationList(response.data);
    });
  }, []);

  const [impactReportTemplateList, setImpactReportTemplateList] = useState([]);
  useEffect(() => {
    getImpactReportTemplates().then((response) => {
      setImpactReportTemplateList(response.data);
    });
  }, []);

  const [impactReportModelList, setImpactReportModelList] = useState([]);
  useEffect(() => {
    getImpactReportModels().then((response) => {
      setImpactReportModelList(response.data);
    });
  }, []);

  return (
    <Container>
      <PageHeader
        title={id ? t('CreateImpactReportDistanceOverridePage.titleModify') : t('CreateImpactReportDistanceOverridePage.titleCreate')}
        hasButton={false}
        hasBackButton={true}
      />
      <CreateImpactReportDistanceOverrideForm impactReportDistanceOverrideId={impactReportDistanceOverrideId} organisationList={organisationList} />
    </Container>
  );
}
