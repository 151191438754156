import { colors, Radio } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getQuizLastAnswerId } from 'services/quiz_answer_log.service';
import { theme } from 'theme';
import { AnswerType, QuestionType } from 'types/quizes.type';

const QuizQuestion = ({
  question,
  userId,
  sessionId,
}: {
  question: QuestionType;
  userId: string;
  sessionId: string;
}) => {
  const {
    i18n: { language },
  } = useTranslation();
  const selectedLanguage = language === 'hu' ? 'text_HU' : 'text_EN';
  const [selectedAnswerId, setSelectedAnswerId] = useState<string | null>(null);

  useEffect(() => {
    if (sessionId.length === 0) return;

    async function handleGetRequest() {
      try {
        const lastAnswerId = await getQuizLastAnswerId(
          userId,
          question.id!,
          sessionId,
        );

        setSelectedAnswerId(lastAnswerId);
      } catch (error) {
        console.error('Error fetching quiz last answer:', error);
      }
    }
    handleGetRequest();
  }, []);

  return (
    <div>
      <h3>{question[selectedLanguage]}</h3>
      {question.answers.map((answer: AnswerType, index: number) => {
        let backgroundColor = '';

        if (answer.id === selectedAnswerId && !answer.isCorrect) {
          backgroundColor = theme.palette.error.main;
        } else if (answer.isCorrect) {
          backgroundColor = theme.palette.primary.main;
        }
        return (
          <div
            key={index}
            style={{
              border: `1px solid ${colors.grey[400]}`,
              paddingLeft: '15px',
              marginBottom: '5px',
              display: 'flex',
              borderRadius: '8px',
              backgroundColor,
            }}
          >
            <Radio disabled checked={answer.id === selectedAnswerId} />
            <p>{answer[selectedLanguage]}</p>
          </div>
        );
      })}
    </div>
  );
};

export default QuizQuestion;
