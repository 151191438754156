import {
  CreateNotificationRequest,
  NotificationType,
} from 'types/notification.type';
import { ApiClient } from './interceptor';

const api = ApiClient.getInstance();

export const getAllNotifications = async (): Promise<Array<NotificationType>> =>
  api
    .get('/notifications')
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });

export const getAllNotificationsBasicData = async (): Promise<Array<NotificationType>> =>
  api
    .get('/notifications/basicData')
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });

export const deleteNotification = async (
  id: string,
): Promise<NotificationType> =>
  api
    .delete(`/notifications/${id}`)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });

export const deleteNotificationBulk = async (notiIds: string[]) => {
  return await api.deleteWithParams('/notifications', {
    notificationIds: notiIds,
  });
};

export const getNotificationById = async (
  id: string,
): Promise<NotificationType> =>
  api
    .get(`/notifications/${id}`)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });

export const createNewNotification = async (
  notification: CreateNotificationRequest,
): Promise<NotificationType> =>
  api
    .post('/notifications', notification)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });

export const updateNotification = async (
  id: string,
  notification: CreateNotificationRequest,
): Promise<NotificationType> =>
  api
    .put(`/notifications/${id}`, notification)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
