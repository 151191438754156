import { Button, Card, FormControl, Grid, InputLabel, MenuItem, Select, styled, TextField, Typography, } from '@mui/material';
import { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import SnackbarContext from 'contexts/snackbarContext';
import { createImpactReportMaintenanceData, getImpactReportMaintenanceDataById, modifyImpactReportMaintenanceData } from 'services/impactReportMaintenanceData.services';

import { addMonths, startOfDay, startOfMonth } from 'date-fns';
import DatePicker from 'react-date-picker';
import { theme } from 'theme';
import { CreateImpactReportMaintenanceDataType, ModifyImpactReportMaintenanceDataType } from 'types/ImpactReportMaintenanceData.type';
import { ImpactReportModelType } from 'types/impactReportModel.type';
import { ImpactReportTemplateType } from 'types/impactReportTemplate.type';
import { OrganisationType } from 'types/organisations.type';

type CreateImpactReportMaintenanceDataFormType = {
  compobotNumber : string;
  measurementDate:Date;
  measurementWeight: number;
  organisation: string;
  location:string;
};

const CustomForm = styled('form')`width: 100%;`;

interface CreateImpactReportMaintenanceDataFormProps {
  impactReportId?: string;
  organisationList: OrganisationType[];
  impactReportTemplateList: ImpactReportTemplateType[];
  impactReportModelList: ImpactReportModelType[];
}

export default function CreateImpactReportMaintenanceDataForm({impactReportId, organisationList, impactReportTemplateList, impactReportModelList} : CreateImpactReportMaintenanceDataFormProps) {
  const { t } = useTranslation();
  const {handleSubmit, formState: { errors }, setValue, control, watch,} = useForm<CreateImpactReportMaintenanceDataFormType>();
  const { handleOpen } = useContext(SnackbarContext);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      getImpactReportMaintenanceDataById(id).then((data) => {
        setValue('compobotNumber', data.compobotNumber);
        setValue('measurementDate', data.measurementDate);
        setValue('measurementWeight', data.measurementWeight);
        setValue('organisation', data.organisation.id);
        setValue('location', data.location);
      });
    }
  }, [id]);

  const onSubmit = (data: CreateImpactReportMaintenanceDataFormType) => {
    if (!id) {
      const impactReprotMaintenanceDataObject: CreateImpactReportMaintenanceDataType = {
        compobotNumber: data.compobotNumber,
        measurementDate: data.measurementDate,
        measurementWeight: data.measurementWeight,
        location: data.location,
        organisationId: data.organisation,
      };
  
      createImpactReportMaintenanceData(impactReprotMaintenanceDataObject)
        .then((result) => {
          navigate('/impactReportMaintenanceDatas/'+result.data.id);
          handleOpen(t('impactReportMaintenanceDataForm.impactReportMaintenanceDataCreatedSuccessfullyMessage'), 'success',);
        })
        .catch(({ data }) => {
          handleOpen(data.message);
        });
    }
    else {

      const modifyImpactReportMaintenanceDataObject: ModifyImpactReportMaintenanceDataType = {
        id: id,
        compobotNumber: data.compobotNumber,
        measurementDate: data.measurementDate,
        measurementWeight: data.measurementWeight,
        location: data.location,
        organisationId: data.organisation,
      };

      modifyImpactReportMaintenanceData(id, modifyImpactReportMaintenanceDataObject)
        .then((result) => {
          handleOpen(t('impactReportTemplateForm.impactReportTemplateModifiedSuccessfullyMessage'), 'success',);

          getImpactReportMaintenanceDataById(id).then((data) => {
            setValue('compobotNumber', data.compobotNumber);
            setValue('measurementDate', data.measurementDate);
            setValue('measurementWeight', data.measurementWeight);
            setValue('organisation', data.organisation.id);
            setValue('location', data.location);
          });
          
        })
        .catch(({ data }) => {
          handleOpen(data.message);
        });

    }
  };

  const checkKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  return (
    <Card sx={{ padding: '40px', maxWidth: '800px' }}>
      <CustomForm onSubmit={handleSubmit(onSubmit)} onKeyDown={checkKeyDown}>
        <Grid container spacing={2}>

          <Grid item xs={6}>
            <Typography style={{ fontSize: 12, color: theme.palette.grey[600] }}>{t('formFields.measurementDate')}</Typography>
            <Controller control={control} name="measurementDate" defaultValue={startOfMonth( addMonths( startOfDay(new Date()),-1))}render={({ field: { value, onChange } }) => (<DatePicker value={  value ? new Date(value) : value} onChange={onChange} />)}/>
          </Grid>
          <Grid item xs={12}><Controller control={control} defaultValue="" name="compobotNumber" render={({ field: { value, onChange } }) => (<TextField onChange={onChange} fullWidth variant="outlined" type="text" value={value} label={t('formFields.compobotNumber')} />)}/></Grid>
          <Grid item xs={12}><Controller control={control} defaultValue={0} name="measurementWeight" render={({ field: { value, onChange } }) => (<TextField onChange={onChange} fullWidth variant="outlined" type="text" value={value} label={t('formFields.measurementWeight')} />)}/></Grid>
          
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="label">{t('formFields.organisation')}</InputLabel>
              <Controller
                name="organisation"
                defaultValue = ""
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select value={value} onChange={onChange} label={t('formFields.organisation')} labelId="label" error={errors.organisation ? true : false}>
                    <MenuItem></MenuItem>
                    {organisationList.map((organisation: OrganisationType) => {return (<MenuItem key={organisation?.id}value={organisation?.id}>{organisation?.companyName}</MenuItem>);})}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}><Controller control={control} defaultValue = "" name="location" render={({ field: { value, onChange } }) => (<TextField onChange={onChange} fullWidth variant="outlined" type="text" value={value} label={t('formFields.location')} />)}/></Grid>

        </Grid>

        <Button variant="contained" sx={{ marginTop: '20px' }} type="submit">{t('formFields.submitButtonLabel')}</Button>
      </CustomForm>
    </Card>
  );
}
