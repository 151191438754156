import { CreateImpactReportDistanceOverrideType, ImpactReportDistanceOverrideType, ModifyImpactReportDistanceOverrideType } from 'types/ImpactReportDistanceOverride.type';
import { ApiClient } from './interceptor';

const api = ApiClient.getInstance();

export const getImpactReportDistanceOverrides = async () => {
  return await api.get('impact-report-distance-override');
};

export const getImpactReportDistanceOverrideById = async (id: string): Promise<ImpactReportDistanceOverrideType> => {
  return await api
    .get(`impact-report-distance-override/${id}`)
    .then((res) => res.data)
    .catch((err) => err);
};

export const deleteImpactReportDistanceOverride = async (ids: string) => {
  return await api.delete(`impact-report-distance-override/${ids}`);
};

export const deleteImpactReportDistanceOverrideBulk = async (ids: string[]) => {
  return await api.deleteWithParams('impact-report-distance-override', { UIds: ids });
};

export const createImpactReportDistanceOverride = async (impactReportDistanceOverride: CreateImpactReportDistanceOverrideType,) => {
  return await api.post('impact-report-distance-override', impactReportDistanceOverride);
};

export const modifyImpactReportDistanceOverride = async (id: string, impactReportDistanceOverride: ModifyImpactReportDistanceOverrideType,) => {
  return await api.put(`impact-report-distance-override/${id}`, impactReportDistanceOverride);
};

export const searchImpactReportDistanceOverrides = async (searchTerm: string) => {
  return await api.get(`impact-report-distance-override/lookup/${searchTerm}`);
};
