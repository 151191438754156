import { Button, Card, Grid, styled, TextField } from '@mui/material';
import { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import SnackbarContext from 'contexts/snackbarContext';

import { createImpactReportModel, getImpactReportModelById, modifyImpactReportModel } from 'services/impactReportModel.services';
import { CreateImpactReportModelType, ImpactReportModelType } from 'types/impactReportModel.type';

type CreateImpactReportModelFormType = {
  name:string;
  description:string;
  parameters: string;
};

const CustomForm = styled('form')`width: 100%;`;

interface CreateImpactReportModelFormProps {
  impactReportModelId?: string;
}

export default function CreateImpactReportSchedulingForm({impactReportModelId:string} : CreateImpactReportModelFormProps) {
  const { t } = useTranslation();
  const {handleSubmit, formState: { errors }, setValue, control, watch,} = useForm<CreateImpactReportModelFormType>();
  const { handleOpen } = useContext(SnackbarContext);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      getImpactReportModelById(id).then((data) => {
        setValue('name', data.name);
        setValue('description', data.description);
        setValue('parameters', data.parameters);
      });
    }
  }, [id]);

  const onSubmit = (data: CreateImpactReportModelFormType) => {
    
    if (!id) {

      const createImpactReportModelObject: CreateImpactReportModelType = {
        name: data.name,
        description: data.description,
        parameters: data.parameters,
      };

      createImpactReportModel(createImpactReportModelObject)
        .then((result) => {
          handleOpen(t('impactReportModelForm.impactReportModelCreatedSuccessfullyMessage'), 'success',);

          navigate('/impactReportModels/'+result.data.id);
        })
        .catch(({ data }) => {
          handleOpen(data.message);
        });
    } else {

      const modifyImpactReportModelObject: ImpactReportModelType = {
        id: id,
        name: data.name,
        description: data.description,
        parameters: data.parameters,
      };

      modifyImpactReportModel(id, modifyImpactReportModelObject)
        .then((result) => {
          handleOpen(t('impactReportModelForm.impactReportModelModifiedSuccessfullyMessage'), 'success',);

          getImpactReportModelById(id).then((data) => {
            setValue('name', data.name);
            setValue('description', data.description);
            setValue('parameters', data.parameters);
          });
          
        })
        .catch(({ data }) => {
          handleOpen(data.message);
        });
    }
  };

  const checkKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  return (
    <Card sx={{ padding: '40px', maxWidth: '800px' }}>
      <CustomForm onSubmit={handleSubmit(onSubmit)} onKeyDown={checkKeyDown}>
        <Grid container spacing={2}>
          <Grid item xs={12}><Controller control={control} defaultValue="" name="name" render={({ field: { value, onChange } }) => (<TextField onChange={onChange} fullWidth variant="outlined" type="text" value={value} label={t('formFields.name')} />)}/></Grid>
          <Grid item xs={12}><Controller control={control} defaultValue="" name="description" render={({ field: { value, onChange } }) => (<TextField onChange={onChange} fullWidth variant="outlined" type="text" value={value} label={t('formFields.description')} />)}/></Grid>
          <Grid item xs={12}><Controller control={control} defaultValue="" name="parameters" render={({ field: { value, onChange } }) => (<TextField onChange={onChange} fullWidth variant="outlined" type="text" value={value} label={t('formFields.parameters')} />)}/></Grid>
        </Grid>

        <Button variant="contained" sx={{ marginTop: '20px' }} type="submit">{t('formFields.submitButtonLabel')}</Button>
      </CustomForm>
    </Card>
  );
}
