import ViewChallengePage from 'components/ViewChallengeForm/ViewChallengeForm';
import DashboardLayout from 'layouts/DashboardLayout';
import AssignCompobotPage from 'pages/AssignCompobotPage/AssignCompobotPage';
import ChallengesPage from 'pages/ChallengesPage/ChallengesPage';
import CompoBotLogsPage from 'pages/CompoBotLogsPage/CompoBotLogsPage';
import CompoBotsPage from 'pages/CompoBotsPage/CompoBotsPage';
import CompoBotTelemetryPage from 'pages/CompoBotTelemetryPage/CompoBotTelemetryPage';
import CreateChallengePage from 'pages/CreateChallengePage/CreateChallengePage';
import CreateGroupPage from 'pages/CreateGroupPage/CreateGroupPage';
import CreateNotificationPage from 'pages/CreateNotificationPage/CreateNotificationPage';
import CreateOrganisationPage from 'pages/CreateOrganizationPage/CreateOrganizationPage';
import EditUserPage from 'pages/EditUserPage/EditUserPage';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import AllGroupsPage from 'pages/GroupsPage/GroupsPage';
import LeadsPage from 'pages/LeadsPage/LeadsPage';
import LoginPage from 'pages/LoginPage/LoginPage';
import NotificationsPage from 'pages/NotificationsPage/NotificationsPage';
import GroupsPage from 'pages/OrganisationGroupsPage/OrganisationGroupsPage';
import OrganisationsPage from 'pages/OrganizationsPage/OrganizationsPage';
import UsersPage from 'pages/UsersPage/UsersPage';
import { createBrowserRouter } from 'react-router-dom';

import ViewQuizPage from 'components/ViewQuizForm/ViewQuizForm';
import CreateQuizPage from 'pages/CreateQuizPage/CreateQuizPage';
import QuizesPage from 'pages/QuizesPage/QuizesPage';
import QuizResultsPage from 'pages/QuizResultsPage/QuizResultsPage';
import QuizStatisticsPage from 'pages/QuizStatisticsPage/QuizStatisticsPage';
import ViewQuizAnswers from '../components/View/ViewQuizResults/ViewQuizAnswers/ViewQuizAnswers';
import ViewQuizResults from '../components/View/ViewQuizResults/ViewQuizResults';

import AdminsPage from '../pages/AdminsPage/AdminsPage';

import CreateImpactReportPage from 'pages/CreateImpactReportPage/CreateImpactReportPage';
import EditImpactReportPage from 'pages/EditImpactReportPage/EditImpactReportPage';
import ImpactReportPage from 'pages/ImpactReportsPage/ImpactReportsPage';

import CreateImpactReportSchedulingPage from 'pages/CreateImpactReportSchedulingPage/CreateImpactReportSchedulingPage';
import ImpactReportSchedulingPage from 'pages/ImpactReportSchedulingsPage/ImpactReportSchedulingsPage';

import ViewQuizStatistics from 'components/View/ViewQuizStatistics/ViewQuizStatistics';
import CreateImpactReportDistanceOverridePage from 'pages/CreateImpactReportDistanceOverridePage/CreateImpactReportDistanceOverridePage';
import CreateImpactReportDistancePage from 'pages/CreateImpactReportDistancePage/CreateImpactReportDistancePage';
import CreateImpactReportMaintenanceDataPage from 'pages/CreateImpactReportMaintenanceDataPage/CreateImpactReportMaintenanceDataPage';
import CreateImpactReportModelPage from 'pages/CreateImpactReportModelPage/CreateImpactReportModelPage';
import CreateImpactReportTemplatePage from 'pages/CreateImpactReportTemplatePage/CreateImpactReportTemplatePage';
import ImpactReportDistanceOverridesPage from 'pages/ImpactReportDistanceOverridesPage/ImpactReportDistanceOverridesPage';
import ImpactReportDistancesPage from 'pages/ImpactReportDistancesPage/ImpactReportDistancesPage';
import ImpactReportMaintenanceDataPage from 'pages/ImpactReportMaintenanceDataPage/ImpactReportMaintenanceDataPage';
import ImpactReportModelsPage from 'pages/ImpactReportModelsPage/ImpactReportModelsPage';
import ImpactReportTemplatePage from 'pages/ImpactReportTemplatesPage/ImpactReportTemplatesPage';
import OrganisationsStatisticsPage from '../pages/OrganizationsStatisticsPage/OrganizationsStatisticsPage';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <LoginPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      { path: 'admins', element: <AdminsPage /> },
      { path: 'users', element: <UsersPage /> },
      { path: 'users/:id', element: <EditUserPage /> },

      { path: 'impactReports', element: <ImpactReportPage /> },
      { path: 'impactReports/:id', element: <EditImpactReportPage /> },
      { path: 'impactReports/new', element: <CreateImpactReportPage /> },

      {
        path: 'impactReportSchedulings',
        element: <ImpactReportSchedulingPage />,
      },
      {
        path: 'impactReportSchedulings/:id',
        element: <CreateImpactReportSchedulingPage />,
      },
      {
        path: 'impactReportSchedulings/new',
        element: <CreateImpactReportSchedulingPage />,
      },

      { path: 'impactReportTemplates', element: <ImpactReportTemplatePage /> },
      {
        path: 'impactReportTemplates/:id',
        element: <CreateImpactReportTemplatePage />,
      },
      {
        path: 'impactReportTemplates/new',
        element: <CreateImpactReportTemplatePage />,
      },

      { path: 'impactReportDistances', element: <ImpactReportDistancesPage /> },
      {
        path: 'impactReportDistances/:id',
        element: <CreateImpactReportDistancePage />,
      },
      {
        path: 'impactReportDistances/new',
        element: <CreateImpactReportDistancePage />,
      },

      {
        path: 'impactReportDistanceOverrides',
        element: <ImpactReportDistanceOverridesPage />,
      },
      {
        path: 'impactReportDistanceOverrides/:id',
        element: <CreateImpactReportDistanceOverridePage />,
      },
      {
        path: 'impactReportDistanceOverrides/new',
        element: <CreateImpactReportDistanceOverridePage />,
      },

      { path: 'impactReportModels', element: <ImpactReportModelsPage /> },
      {
        path: 'impactReportModels/:id',
        element: <CreateImpactReportModelPage />,
      },
      {
        path: 'impactReportModels/new',
        element: <CreateImpactReportModelPage />,
      },

      {
        path: 'impactReportMaintenanceData',
        element: <ImpactReportMaintenanceDataPage />,
      },
      {
        path: 'impactReportMaintenanceData/:id',
        element: <CreateImpactReportMaintenanceDataPage />,
      },
      {
        path: 'impactReportMaintenanceData/new',
        element: <CreateImpactReportMaintenanceDataPage />,
      },

      { path: 'organisations', element: <OrganisationsPage /> },
      {
        path: 'organisations/statistics',
        element: <OrganisationsStatisticsPage />,
      },
      { path: 'organisations/new', element: <CreateOrganisationPage /> },
      { path: 'organisations/:id', element: <CreateOrganisationPage /> },
      { path: 'organisations/:id/groups', element: <GroupsPage /> },
      { path: 'organisations/:id/groups/new', element: <CreateGroupPage /> },
      {
        path: 'organisations/:id/groups/:groupId',
        element: <CreateGroupPage />,
      },

      { path: 'allgroups', element: <AllGroupsPage /> },
      { path: 'allgroups/new', element: <CreateGroupPage /> },
      { path: 'allgroups/:groupId', element: <CreateGroupPage /> },
      { path: 'leads', element: <LeadsPage /> },

      { path: 'compobots', element: <CompoBotsPage /> },
      { path: 'compobots/new', element: <AssignCompobotPage /> },
      { path: 'compobots/:id', element: <AssignCompobotPage /> },
      { path: 'compobots/logs', element: <CompoBotLogsPage /> },
      { path: 'compobots/telemetry', element: <CompoBotTelemetryPage /> },
      { path: 'challenges', element: <ChallengesPage /> },
      { path: 'challenges/:id', element: <CreateChallengePage /> },
      { path: 'challenges/new', element: <CreateChallengePage /> },
      { path: 'notifications', element: <NotificationsPage /> },
      { path: 'notifications/:id', element: <CreateNotificationPage /> },
      { path: 'notifications/new', element: <CreateNotificationPage /> },
      { path: 'view/challenge/:id', element: <ViewChallengePage /> },

      { path: 'quiz', element: <QuizesPage /> },
      { path: 'quiz/statistics', element: <QuizStatisticsPage /> },
      { path: 'quiz/results', element: <QuizResultsPage /> },
      { path: 'quiz/new', element: <CreateQuizPage /> },
      { path: 'quiz/:id', element: <CreateQuizPage /> },
      { path: 'quiz/:id/results', element: <ViewQuizResults /> },
      { path: 'quiz/:id/statistics', element: <ViewQuizStatistics /> },
      { path: 'quiz/:id/results/user/:id', element: <ViewQuizAnswers /> },
      { path: 'view/quiz/:id', element: <ViewQuizPage /> },
    ],
  },
]);
