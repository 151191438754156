import { Button, Card, Checkbox, Container, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from '@mui/material';
import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import PageHeader from 'components/PageHeader/PageHeader';
import Popup from 'components/Popup/Popup';
import MoreMenu from 'components/table/MoreMenu';
import SearchNotFound from 'components/table/SearchNoFound';
import TableHeader from 'components/table/TableHeader';
import TableToolBar from 'components/table/TableToolbar';
import { getImpactReportTableHead } from 'constans/table-headers';
import SnackbarContext from 'contexts/snackbarContext';
import { handleClick, handleSelectAllClick, sortByColumn, } from 'services/list.service';

import {
  deleteImpactReport,
  deleteImpactReportBulk,
  getImpactReports,
  searchImpactReports,
} from 'services/impactReport.services';

import { ImpactReportType } from 'types/impactReport.type';

export default function impactReportPage() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [selected, setSelected] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { handleOpen } = useContext(SnackbarContext);
  const [impactReportList, setImpactReportList] = useState<ImpactReportType[]>([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    getImpactReportsHandler();
  }, []);

  const getImpactReportsHandler = () => {
    getImpactReports().then((response) => {

      setImpactReportList(response.data);


    });
  };

  const searchFilter = useCallback(
    debounce((filterName) => {
      if (filterName) {
        searchImpactReports(filterName).then((response) => {
          setImpactReportList(response.data);
        });
      } else {
        getImpactReportsHandler();
      }
    }, 300),
    [],
  );

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteImpactReport = (impactReportId: string) => {
    deleteImpactReport(impactReportId)
      .then(() => {
        setImpactReportList([...impactReportList].filter(({ id }) => id !== impactReportId));
        handleSelectAllClick(false, impactReportList);
        setOpenModal(false);
        handleOpen(t('impactReportPage.impactReportDeletedSuccessMessage'), 'success');
      })
      .catch(({ data }) => {
        handleOpen(data.message);
      });
  };

  const handleDeleteImpactReportBulk = (impactReportIds: string[]) => {
    deleteImpactReportBulk(impactReportIds)
      .then(() => {
        setImpactReportList(impactReportList.filter(({ id }) => !impactReportIds.includes(id)));
        handleSelectAllClick(false, impactReportList);
        setOpenModal(false);
        handleOpen(t('usersPage.impactReportsDeletedSuccessMessage'), 'success');
      })
      .catch(({ data }) => {
        handleOpen(data.message);
      });
  };

  const deleteImpactReportAction = () => {
    if (selected.length > 1) {
      handleDeleteImpactReportBulk(selected);
    } else {
      handleDeleteImpactReport(selected[0]);
    }
  };

  const openModalHandler = (id?: string) => {
    setOpenModal(true);
    if (id) {
      setSelected([id]);
    }
  };

  const sortedImpactReportList = sortByColumn(
    impactReportList,
    orderBy as keyof ImpactReportType,
    order,
  );

  const impactReportTableHead = useMemo(getImpactReportTableHead, [language]);

  return (
    <Container>
      <PageHeader title={t('impactReportPage.title')} buttonTitle={t('impactReportPage.newImpactReportbuttonTitle').toString()} hasButton/>

      <Button variant="outlined" sx={{ margin: '0px 5px 20px 5px' }} component={RouterLink} to={'/impactReportTemplates'}>         {t('impactReportPage.templatesButtonTitle')}</Button>
      <Button variant="outlined" sx={{ margin: '0px 5px 20px 5px' }} component={RouterLink} to={'/impactReportSchedulings'}>       {t('impactReportPage.schedulingsButtonTitle')}</Button>
      <Button variant="outlined" sx={{ margin: '0px 5px 20px 5px' }} component={RouterLink} to={'/impactReportDistances'}>         {t('impactReportPage.distancesButtonTitle')}</Button>
      <Button variant="outlined" sx={{ margin: '0px 5px 20px 5px' }} component={RouterLink} to={'/impactReportDistanceOverrides'}> {t('impactReportPage.distanceOverridesButtonTitle')}</Button>
      <Button variant="outlined" sx={{ margin: '0px 5px 20px 5px' }} component={RouterLink} to={'/impactReportModels'}>            {t('impactReportPage.modelsButtonTitle')}</Button>
      <Button variant="outlined" sx={{ margin: '0px 5px 20px 5px' }} component={RouterLink} to={'/impactReportMaintenanceData'}>   {t('impactReportPage.maintenanceDataButtonTitle')}</Button>

      {/* 
      <Button variant="contained" sx={{ margin: '20px' }} startIcon={<AddIcon />}> {t('formFields.submitButtonLabel')}</Button>
      <Button variant="contained" sx={{ marginTop: '20px' }}  onClick={() => console.log('click') }> {t('formFields.submitButtonLabel')}</Button>
      <IconButton color="primary" onClick={() => handleMyClick()}><DeleteForeverIcon /></IconButton>
      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={false} >{t('login.loginButton')}</LoadingButton>
      */}

      <Card>
        <TableToolBar
          numSelected={selected.length}
          onFilterName={searchFilter}
          deleteHandler={() => openModalHandler()}
        />

        <TableContainer>
          <Table>
            {
              <TableHeader
                order={order}
                orderBy={orderBy}
                headLabel={impactReportTableHead}
                rowCount={impactReportList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={(checked) =>
                  setSelected(handleSelectAllClick(checked, impactReportList))
                }
              />
            }
            <TableBody>
              {sortedImpactReportList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const {
                    id,
                    organisation,
                    createdAt,
                    dateFrom,
                    dateTo,
                    //documentPath,
                    //model,
                    //template
                  } = row;
                  const isItemSelected = selected.indexOf(id) !== -1;

                  return (
                    <TableRow
                      hover
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >

                      <TableCell padding="checkbox"><Checkbox checked={isItemSelected}onClick={() => setSelected(handleClick(id, selected))}/></TableCell>
                      <TableCell align="left">{organisation.companyName}</TableCell>
                      <TableCell align="left">{new Date(dateFrom).toLocaleDateString('hu-HU').replaceAll(' ', '') }</TableCell>
                      <TableCell align="left">{new Date(dateTo).toLocaleDateString('hu-HU').replaceAll(' ', '')  }</TableCell>
                      <TableCell align="left">{new Date(createdAt).toLocaleDateString('hu-HU').replaceAll(' ', '') + ' ' +  new Date(createdAt).toLocaleTimeString('hu-HU').replaceAll(' ', '') }</TableCell>


                      

                      <TableCell align="right">
                        <MoreMenu
                          onDelete={() => openModalHandler(id)}
                          id={id}
                        />
                      </TableCell>

                    </TableRow>
                  );
                })}
            </TableBody>
            {!impactReportList.length && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={impactReportList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, page) => setPage(page)}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('pagination.rowsPerPage')}
        />
      </Card>
      <Popup
        type="delete"
        open={openModal}
        onConfirm={deleteImpactReportAction}
        handleClose={() => setOpenModal(false)}
      />
    </Container>
  );
}
