import { ChallengeType } from './challenges.type';
import { UserType } from './users.type';

export type CreateQuizType = {
  challenges: ChallengeType[] | null;
  title_EN: string;
  title_HU: string;
  scoreForCorrect: number;
  challengeIds: string[];
  questions: QuestionType[];
  startDate: Date | null;
  endDate: Date | null;
};

export type QuestionType = {
  id?: string;
  text_EN: string;
  text_HU: string;
  answers: AnswerType[];
  photo?: File;
  photoUrl?: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export type AnswerType = {
  id?: string;
  text_EN: string;
  text_HU: string;
  isCorrect: boolean;
  createdAt?: Date;
  updatedAt?: Date;
};

export type QuizType = {
  id: string;
  title_EN: string;
  title_HU: string;
  scoreForCorrect: number;
  quizAnswerLogs?: QuizAnswerLogType[];
  createdAt?: Date;
  updatedAt?: Date;
  challenges: ChallengeType[];
  questions: QuestionType[];
  startDate?: Date | undefined;
  endDate?: Date | undefined;
};

export type QuizLogType = {
  id: string;
  numberOfUsers: number | undefined;
  quiz: QuizType;
  score: number;
  timestamp: Date;
  user: UserType;
};

export type QuizAnswerLogType = {
  id: string;
  quizAnswerId: string;
  quizAnswer: QuizAnswerType;
  quizId: string;
  quizQuestionId: string;
  sessionId: string;
  userId: string;
};

export type QuizAnswerType = {
  id: string;
  title_EN: string;
  isCorrect: boolean;
  title_HU: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export function createQuizToFormData(quiz: CreateQuizType): FormData {
  const formData = new FormData();

  formData.append('quizData', JSON.stringify(quiz));

  quiz.questions.forEach((question, index) => {
    if (question.photo) {
      formData.append(`photo_${index}`, question.photo);
    }
  });

  return formData;
}

export type QuizResultType = {
  id: string;

  timestamp: Date;
  quizId: string;

  quizTitle_EN: string;

  userId: string;
  userName: string;
  userEmail: string;

  organisationId: string;
  organisationName: string;

  score: number;
  resultPercentage: number;
};

export type QuizStatisticsType = {
  id: string;

  title_EN: string;

  numberOfResults: number;
  averageResults: number;
  minResults: number;
  maxResults: number;
};
