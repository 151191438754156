import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import CreateImpactReportMaintenanceDataForm from 'components/CreateImpactReportMaintenanceDataForm/CreateImpactReportMaintenanceDataForm';
import PageHeader from 'components/PageHeader/PageHeader';
import { useEffect, useState } from 'react';
import { getImpactReportModels } from 'services/impactReportModel.services';
import { getImpactReportTemplates } from 'services/impactReportTemplate.services';
import { getOrganizations } from 'services/organisation.service';

export default function CreateImpactReportMaintenanceDataPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const impactReportId = id || '';

  const [organisationList, setOrganisationList] = useState([]);
  useEffect(() => {
    getOrganizations().then((response) => {
      setOrganisationList(response.data);
    });
  }, []);

  const [impactReportTemplateList, setImpactReportTemplateList] = useState([]);
  useEffect(() => {
    getImpactReportTemplates().then((response) => {
      setImpactReportTemplateList(response.data);
    });
  }, []);

  const [impactReportModelList, setImpactReportModelList] = useState([]);
  useEffect(() => {
    getImpactReportModels().then((response) => {
      setImpactReportModelList(response.data);
    });
  }, []);

  return (
    <Container>
      <PageHeader
        title={id ? t('CreateImpactReportMaintenanceDataPage.titleModify') : t('CreateImpactReportMaintenanceDataPage.titleCreate')}
        hasButton={false}
        hasBackButton={true}
      />
      <CreateImpactReportMaintenanceDataForm  impactReportId={impactReportId} organisationList={organisationList}  impactReportTemplateList={impactReportTemplateList}  impactReportModelList={impactReportModelList} />
    </Container>
  );
}
