import { Card, Container } from '@mui/material';
import PageHeader from 'components/PageHeader/PageHeader';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { getQuizQuestionStatistics } from 'services/quiz.service';
import { QuestionType, QuizType } from 'types/quizes.type';
import QuizQuestionStatistics from './QuizQuestionStatistics';

const ViewQuizStatistics = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const selectedLanguage = language === 'hu' ? 'title_HU' : 'title_EN';
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [quiz, setQuiz] = useState<QuizType | null>(null);

  const fullPathName = pathname.split('/');

  //TODO: refactor hardcoded values
  const quizId = fullPathName[fullPathName.length - 2];

  useEffect(() => {
    async function handleGetRequest() {
      setIsLoading(true);
      try {
        const responseQuiz = await getQuizQuestionStatistics(quizId);
        setQuiz(responseQuiz.data);
      } catch (error) {
        console.error('Error fetching quiz last session:', error);
      } finally {
        setIsLoading(false);
      }
    }

    handleGetRequest();
  }, []);

  return (
    <Container>
      <PageHeader
        title={`${t('singleQuizStatisticsPage.title')} - ${
          quiz?.[selectedLanguage]
        }`}
        hasButton={false}
        hasBackButton={true}
      />
      <Card
        style={{
          padding: '20px',
        }}
      >
        {quiz?.questions?.map((question: QuestionType) => {
          const getAllAnswerLogs = quiz.quizAnswerLogs?.filter(
            (log) => log.quizQuestionId === question.id,
          );

          return (
            <QuizQuestionStatistics
              key={question.id}
              question={question}
              answerLogs={getAllAnswerLogs ?? []}
            />
          );
        })}
      </Card>
    </Container>
  );
};

export default ViewQuizStatistics;
