import { AxiosError } from 'axios';
import { ApiClient } from './interceptor';

const api = ApiClient.getInstance();

const getQuizLastSessionId = async ({
  userId,
  quizId,
}: {
  userId: string;
  quizId: string;
}): Promise<string> => {
  try {
    const res = await api.get(
      `/quiz-answer-log/user/${userId}/quiz/${quizId}/last-session-id`,
    );

    return res.data;
  } catch (error) {
    const axiosError = error as AxiosError;

    if (axiosError.response) {
      throw axiosError;
    } else if (axiosError.request) {
      throw new AxiosError('No response received');
    } else {
      throw axiosError;
    }
  }
};

const getQuizLastAnswerId = async (
  userId: string,
  quizQuestionId: string,
  sessionId: string,
): Promise<string> => {
  try {
    const res = await api.get(
      `/quiz-answer-log/user/${userId}/session/${sessionId}/question/${quizQuestionId}/last-answer`,
    );

    return res.data;
  } catch (error) {
    const axiosError = error as AxiosError;

    if (axiosError.response) {
      throw axiosError;
    } else if (axiosError.request) {
      throw new AxiosError('No response received');
    } else {
      throw axiosError;
    }
  }
};

export { getQuizLastAnswerId, getQuizLastSessionId };
