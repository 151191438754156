import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import CreateImpactReportTemplateForm from 'components/CreateImpactReportTemplateForm/CreateImpactReportTemplateForm';
import PageHeader from 'components/PageHeader/PageHeader';

export default function CreateImpactReportTemplatePage() {
  const { t } = useTranslation();
  const { id } = useParams();
  
  const impactReportTemplateId = id || '';

  return (
    <Container>
      <PageHeader title={id ? t('createImpactReportTemplatePage.titleModify') : t('createImpactReportTemplatePage.titleCreate')} hasButton={false} hasBackButton={true} />
      <CreateImpactReportTemplateForm  impactReportTemplateId={impactReportTemplateId} />
    </Container>
  );
}
