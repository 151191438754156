import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import CreateImpactReportDistanceForm from 'components/CreateImpactReportDistanceForm/CreateImpactReportDistanceForm';
import PageHeader from 'components/PageHeader/PageHeader';

export default function CreateImpactReportTemplatePage() {
  const { t } = useTranslation();
  const { id } = useParams();
  
  const impactReportDistanceId = id || '';

  return (
    <Container>
      <PageHeader title={id ? t('createImpactReportDistancePage.titleModify') : t('createImpactReportDistancePage.titleCreate')} hasButton={false} hasBackButton={true} />
      <CreateImpactReportDistanceForm  impactReportDistanceId={impactReportDistanceId} />
    </Container>
  );
}
