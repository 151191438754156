/* eslint-disable indent */
import {
  Box, Button,
  Card,
  Container, Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow, TextField,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import PageHeader from 'components/PageHeader/PageHeader';
import Popup from 'components/Popup/Popup';
import MoreMenu from 'components/table/MoreMenu';
import TableHeader from 'components/table/TableHeader';
import { getQuizFillsTableHead } from 'constans/table-headers';
import SnackbarContext from 'contexts/snackbarContext';
import DateTimePicker from 'react-datetime-picker';
import { handleSelectAllClick, sortByColumn } from 'services/list.service';
import { deleteQuiz } from 'services/quiz.service';
import { QuizResultType } from 'types/quizes.type';
import { getQuizResults } from '../../services/quiz_log.service';
import { addMonths, endOfDay, lastDayOfMonth, startOfDay, startOfMonth } from 'date-fns';
import { theme } from '../../theme';

export default function QuizResultsPage() {
  const { t, i18n: { language }, } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [selected, setSelected] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { handleOpen } = useContext(SnackbarContext);
  const [quizList, setQuizResultsList] = useState<QuizResultType[]>([]);
  const [openModal, setOpenModal] = useState(false);

  const [startDate, setStartDate] = useState(startOfMonth( ( startOfDay(new Date()))));
  const [endDate, setEndDate] = useState( endOfDay(new Date()));

  useEffect(() => {getQuizResultsHandler();}, []);

  const getQuizResultsHandler = () => {getQuizResults(startDate, endDate).then((response) => {setQuizResultsList(response.data);});};

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>,) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getQuizResultsList = () => {
    getQuizResults(startDate, endDate).then(({ data }) => setQuizResultsList(data));
  };

  const deleteQuizHandler = (id: string) => {
    deleteQuiz(id).then(() => {
      setOpenModal(false);
      handleOpen(t('challengesPage.challengeDeletedSuccessMessage'), 'success');
      getQuizResultsList();
    });
  };

  const openModalHandler = (id?: string) => {
    setOpenModal(true);

    if (id) {
      setSelected([id]);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleView = (quizId: string, userId: string) => {
    navigate(`/quiz/${quizId}/results/user/${userId}`);
  };

  const sortedQuiz = sortByColumn(
    quizList,
    orderBy as keyof QuizResultType,
    order,
  );

  const refreshData = () => {
    getQuizResultsList();
  };

  const quizResultsTableHead = useMemo(getQuizFillsTableHead, [language]);

  return (
    <Container>
      <PageHeader
        title={t('quizResultsPage.title')}
        hasButton={false}
        hasBackButton={true}
      />


     <Box style={{ display: 'flex', textAlign: 'center', paddingBottom: '10px' }}>
       <Grid container spacing={1}>
         <Grid item xs={4} >
           <Typography style={{ fontSize: 12, color: theme.palette.grey[600] }}>{t('formFields.dateFrom')}</Typography>
           <DateTimePicker
            value={startDate}
            onChange={(date: Date | null) => {if (date) {setStartDate(date);}}}
            minDetail="year"
            maxDate={endDate}
            disableClock
            clearIcon={null}
            format="yyyy.MM.dd."
          />
         </Grid>
         <Grid item xs={4}>
           <Typography style={{ fontSize: 12, color: theme.palette.grey[600] }}>{t('formFields.dateTo')}</Typography>
           <DateTimePicker
             value={endDate}
             onChange={(date: Date | null) => {if (date) {setEndDate(date);}}}
             minDate={startDate}
             minDetail="year"
             disableClock
             clearIcon={null}
             format="yyyy.MM.dd."
           />
         </Grid>
         <Grid item xs={2}>
           <Button variant="contained" onClick={refreshData}>{t('quizResultsPage.refresh')}</Button>
         </Grid>
       </Grid>
      </Box>

      <Card sx={{ paddingTop: 1 }}>
        <TableContainer>
          <Table>
            <TableHeader
              order={order}
              orderBy={orderBy}
              headLabel={quizResultsTableHead}
              rowCount={quizList.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={(checked) =>
                setSelected(handleSelectAllClick(checked, quizList))
              }
              disableSelect
            />
            {/* If there will be a search put the TableHeader here */}
            <TableBody>
              {sortedQuiz
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((quizResult) => (
                  <TableRow
                    hover
                    key={quizResult.id}
                    tabIndex={-1}
                    role="checkbox"
                    selected={selected.indexOf(quizResult.id) !== -1}
                    aria-checked={selected.indexOf(quizResult.id) !== -1}
                  >
                    <TableCell component="th" scope="row" padding="none">
                      <Stack direction="row" alignItems="left" spacing={2}>
                        <Typography
                          variant="subtitle2"
                          noWrap
                          sx={{ fontWeight: 600 }}
                        >
                          {new Date(quizResult.timestamp)
                            .toLocaleDateString('hu-HU')
                            .replaceAll(' ', '') +
                            ' ' +
                            new Date(quizResult.timestamp).toLocaleTimeString(
                              'hu-HU',
                            )}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell align="left">
                      {quizResult.quizTitle_EN}
                    </TableCell>

                    <TableCell align="left">{quizResult.userName}</TableCell>
                    <TableCell align="left">{quizResult.userEmail}</TableCell>

                    <TableCell align="left">{quizResult.score}</TableCell>
                    <TableCell align="left">
                      {quizResult.resultPercentage}
                    </TableCell>

                    <TableCell align="left">
                      {quizResult.organisationName}
                    </TableCell>

                    <TableCell align="right">
                      <MoreMenu
                        onPreview={() =>
                          handleView(quizResult.quizId, quizResult.userId)
                        }
                        hideEdit
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            {!quizList.length && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    {t('quizesPage.noQuizes')}
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={quizList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, page) => setPage(page)}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('pagination.rowsPerPage')}
        />
      </Card>
      <Popup
        type="delete"
        open={openModal}
        onConfirm={() => deleteQuizHandler(selected[0])}
        handleClose={() => setOpenModal(false)}
      />
    </Container>
  );
}
